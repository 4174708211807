// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.finance{
    justify-content: center;
    align-items: center;
    display: flex;
}
.reportNotAvailable{
    justify-content: center;
    align-items: center;
    display: flex;
    min-height: 500px;
}
.financeReport{
    width: 1024px;
}
.orgLogo{
    justify-content: center;
    display: flex;
    align-items: center;
}
.orgTitle{
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.orgName{
    display: flex;
    justify-content: center;
    font-size: 26px;
    font-weight: bold;
    text-align: center;
}
.orgNumber{
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}
.reportdate{
    justify-content: right;
    display: flex;
}`, "",{"version":3,"sources":["webpack://./src/Pages/FinanceReportPage/FinanceReportPage.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;AACA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,iBAAiB;AACrB;AACA;IACI,aAAa;AACjB;AACA;IACI,uBAAuB;IACvB,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,sBAAsB;IACtB,aAAa;AACjB","sourcesContent":[".finance{\r\n    justify-content: center;\r\n    align-items: center;\r\n    display: flex;\r\n}\r\n.reportNotAvailable{\r\n    justify-content: center;\r\n    align-items: center;\r\n    display: flex;\r\n    min-height: 500px;\r\n}\r\n.financeReport{\r\n    width: 1024px;\r\n}\r\n.orgLogo{\r\n    justify-content: center;\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n.orgTitle{\r\n    height: 100px;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    text-align: center;\r\n}\r\n.orgName{\r\n    display: flex;\r\n    justify-content: center;\r\n    font-size: 26px;\r\n    font-weight: bold;\r\n    text-align: center;\r\n}\r\n.orgNumber{\r\n    display: flex;\r\n    justify-content: center;\r\n    font-size: 16px;\r\n    font-weight: bold;\r\n    text-align: center;\r\n}\r\n.reportdate{\r\n    justify-content: right;\r\n    display: flex;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
