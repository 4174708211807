// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs_container{
}
.tabs_selecter{
    display: flex;
    border-bottom: 1px black solid;
    
}
.tab{
    padding: 20px 40px;
    cursor: pointer;
}
.tab_selected{
    padding: 20px 40px;
    border: 1px solid black;
    border-bottom: 0;
    font-weight: bold;
    cursor: pointer;
}
.current_tab{

}`, "",{"version":3,"sources":["webpack://./src/Components/BasicComponents/Tabs.css"],"names":[],"mappings":"AAAA;AACA;AACA;IACI,aAAa;IACb,8BAA8B;;AAElC;AACA;IACI,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;AACnB;AACA;;AAEA","sourcesContent":[".tabs_container{\r\n}\r\n.tabs_selecter{\r\n    display: flex;\r\n    border-bottom: 1px black solid;\r\n    \r\n}\r\n.tab{\r\n    padding: 20px 40px;\r\n    cursor: pointer;\r\n}\r\n.tab_selected{\r\n    padding: 20px 40px;\r\n    border: 1px solid black;\r\n    border-bottom: 0;\r\n    font-weight: bold;\r\n    cursor: pointer;\r\n}\r\n.current_tab{\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
