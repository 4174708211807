// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn_button{
    min-height: 40px;
    border: 0;
    border-radius: 20px;
    padding: 8px 30px;
    cursor: pointer;
    display: flex;
    background-color: #2d7ff9;
    color: white;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    margin: 10px 0;
}
.btn_default{
    margin-left: 10px;
    max-width: 200px;
}

.btn_button:hover, .btn_button:focus, .btn_button:active{
    outline:0;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
}`, "",{"version":3,"sources":["webpack://./src/Components/BasicComponents/Button.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,SAAS;IACT,mBAAmB;IACnB,iBAAiB;IACjB,eAAe;IACf,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,cAAc;AAClB;AACA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,SAAS;IACT,+GAA+G;AACnH","sourcesContent":[".btn_button{\r\n    min-height: 40px;\r\n    border: 0;\r\n    border-radius: 20px;\r\n    padding: 8px 30px;\r\n    cursor: pointer;\r\n    display: flex;\r\n    background-color: #2d7ff9;\r\n    color: white;\r\n    justify-content: center;\r\n    align-items: center;\r\n    font-size: 14px;\r\n    margin: 10px 0;\r\n}\r\n.btn_default{\r\n    margin-left: 10px;\r\n    max-width: 200px;\r\n}\r\n\r\n.btn_button:hover, .btn_button:focus, .btn_button:active{\r\n    outline:0;\r\n    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
