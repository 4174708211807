import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Button.css';

export class Button extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    class: PropTypes.string, 
    style: PropTypes.object
  };
  render() {
    const className = this.props.className ? classNames("btn_button",this.props.className) : classNames("btn_button", "btn_default", this.props.class ? ("btn_" + this.props.class) : "btn_black", this.props.className);
    return (
        <React.Fragment>
            <button
                className={className}
                onClick={event => this.props.onClick(event)}
                style={this.props.style}
            >
                {this.props.children}
            </button>
        </React.Fragment>
    );
  }
}
