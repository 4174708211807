// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.public_page{
    display: block;
    width: 100%;
}
.public_page_header{
    height: 100px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}
.public_page_body{
    width: 100%;
    margin-top: 100px;
    min-height: 50%;
    margin-bottom: 30px;
}

@media (max-width:576px){
    .public_page_body{
        width: 100%;
        margin-top: 80px;
        min-height: 50%;
    }
}`, "",{"version":3,"sources":["webpack://./src/Route/Route.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,WAAW;AACf;AACA;IACI,aAAa;IACb,eAAe;IACf,MAAM;IACN,WAAW;IACX,YAAY;AAChB;AACA;IACI,WAAW;IACX,iBAAiB;IACjB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI;QACI,WAAW;QACX,gBAAgB;QAChB,eAAe;IACnB;AACJ","sourcesContent":[".public_page{\r\n    display: block;\r\n    width: 100%;\r\n}\r\n.public_page_header{\r\n    height: 100px;\r\n    position: fixed;\r\n    top: 0;\r\n    width: 100%;\r\n    z-index: 100;\r\n}\r\n.public_page_body{\r\n    width: 100%;\r\n    margin-top: 100px;\r\n    min-height: 50%;\r\n    margin-bottom: 30px;\r\n}\r\n\r\n@media (max-width:576px){\r\n    .public_page_body{\r\n        width: 100%;\r\n        margin-top: 80px;\r\n        min-height: 50%;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
