// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch-group{
  width: 40px;
  height: 20px;
  margin: 0 10px;
  position: relative;
}
.switch{
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-bottom: 0.5rem;
}
.switch__slider:before{
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  transition: .4s;
}
.switch__slider{
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 10px;
  transition: .4s;
}

.switch input:checked + .switch__slider {
  background-color: #28A834;
}

.switch input:checked + .switch__slider:before {
  transform: translateX(20px);
}`, "",{"version":3,"sources":["webpack://./src/Components/BasicComponents/SwitchButton.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,qBAAqB;AACvB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,WAAW;EACX,SAAS;EACT,WAAW;EACX,uBAAuB;EACvB,kBAAkB;EAElB,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,sBAAsB;EACtB,mBAAmB;EAEnB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EAGE,2BAA2B;AAC7B","sourcesContent":[".switch-group{\r\n  width: 40px;\r\n  height: 20px;\r\n  margin: 0 10px;\r\n  position: relative;\r\n}\r\n.switch{\r\n  position: absolute;\r\n  top: 0;\r\n  right: 0;\r\n  display: inline-block;\r\n  width: 40px;\r\n  height: 20px;\r\n  margin-bottom: 0.5rem;\r\n}\r\n.switch__slider:before{\r\n  position: absolute;\r\n  content: \"\";\r\n  height: 12px;\r\n  width: 12px;\r\n  left: 4px;\r\n  bottom: 4px;\r\n  background-color: white;\r\n  border-radius: 50%;\r\n  -webkit-transition: .4s;\r\n  transition: .4s;\r\n}\r\n.switch__slider{\r\n  position: absolute;\r\n  cursor: pointer;\r\n  top: 0;\r\n  left: 0;\r\n  right: 0;\r\n  bottom: 0;\r\n  background-color: #ccc;\r\n  border-radius: 10px;\r\n  -webkit-transition: .4s;\r\n  transition: .4s;\r\n}\r\n\r\n.switch input:checked + .switch__slider {\r\n  background-color: #28A834;\r\n}\r\n\r\n.switch input:checked + .switch__slider:before {\r\n  -webkit-transform: translateX(20px);\r\n  -ms-transform: translateX(20px);\r\n  transform: translateX(20px);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
