import { MenuHolder } from "../Components";

export function showDev(data, user) {
    if (data.dev === "true") {
        if (localStorage.getItem("developerMode") === "true") {
            if (data.show === "both"){
                return true;
            } else if (data.show === "private") {
                return user !== null && user !== undefined;
            } else if (data.show === "public") {
                return user === undefined;
            }
            return true;
        } else {
            return false;
        }
    } else {
        if (data.show === "both"){
            return true;
        } else if (data.show === "private") {
            if (data.admin) {
                if (user?.role === "Admin") {
                    return user !== null && user !== undefined;
                } else {
                    return false;
                }
            } else {
                return user !== null && user !== undefined;
            }
        } else if (data.show === "public") {
            return user === undefined;
        }
        return true;
    }
}

export function getAddress(x) {
    if (x.streetAddress) {
        return <>{x.streetAddress}<br />{x.city}<br />{x.country}{x.postalCode ? "-" : ""}{x.postalCode}</>;
    } else {
        return <div>Address not available.</div>;
    }
}

export function getAction(row, col, actionOpenId, callback) {
    return (
        <>
            <button className="approve_membership" type="button" onClick={() => {callback(row.id)}}>
                <svg width="15px" height="15px" viewBox="0 0 22 22">
                    <circle cx="12" cy="12" r="2" fill="currentColor"></circle>
                    <circle cx="12" cy="5" r="2" fill="currentColor"></circle>
                    <circle cx="12" cy="19" r="2" fill="currentColor"></circle>
                </svg>
            </button>
            <MenuHolder open={actionOpenId === row.id} top={"20px"} right={"35px"} closePopup={() => {callback(-1)}} outsideClickClose>
                <div className="action_popup" tabIndex="-1">
                    <span className="action_pointer"></span>
                    {
                        col.actions.map((x,i)=>{
                            return(
                                <button key={i} className='action_button' onClick={()=> x.onclick(row)}>
                                    {x.name}
                                </button>
                            )
                        })
                    }
                </div>
            </MenuHolder>
        </>
    )
}