// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nf_404{
    font-size: 100pt;
    color: coral;
    text-align: center;
}
.nf_container{
    color: #1D3557;
    font-weight: bold;
    padding-bottom: 4px;
    padding-right:15px;
    padding-left:15px;
    margin:auto;
}
@media (min-width:576px){.nf_container{width:540px}}
@media (min-width:768px){.nf_container{width:720px}}
@media (min-width:992px){.nf_container{width:960px}}
@media (min-width:1200px){.nf_container{width:1140px}}`, "",{"version":3,"sources":["webpack://./src/Pages/ErrorPage/ErrorPage.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,cAAc;IACd,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;AACf;AACA,yBAAyB,cAAc,WAAW,CAAC;AACnD,yBAAyB,cAAc,WAAW,CAAC;AACnD,yBAAyB,cAAc,WAAW,CAAC;AACnD,0BAA0B,cAAc,YAAY,CAAC","sourcesContent":[".nf_404{\r\n    font-size: 100pt;\r\n    color: coral;\r\n    text-align: center;\r\n}\r\n.nf_container{\r\n    color: #1D3557;\r\n    font-weight: bold;\r\n    padding-bottom: 4px;\r\n    padding-right:15px;\r\n    padding-left:15px;\r\n    margin:auto;\r\n}\r\n@media (min-width:576px){.nf_container{width:540px}}\r\n@media (min-width:768px){.nf_container{width:720px}}\r\n@media (min-width:992px){.nf_container{width:960px}}\r\n@media (min-width:1200px){.nf_container{width:1140px}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
