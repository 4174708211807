import { EventActions } from '../Actions';

const initialState = {
  list: [],
  eventDetails: {}
}

export const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case EventActions.LIST: {
      return {
        ...state,
        list: action.payload
      };
    }
    case EventActions.DETAIL: {
      return {
        ...state,
        eventDetails: action.payload
      };
    }
    case EventActions.UPDATE: {
      return {
        ...state,
        ...action.payload
      };
    }
    case EventActions.NOTES: {
      return {
        ...state,
        notes: "",
        addNotes: false,
        comment: "",
        eventDetails: {
          ...state.eventDetails,
          eventDetail: action.payload
        }
      };
    }
    default:
      return state;
  }
};
