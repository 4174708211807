import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './ErrorPage.css';
import { ClearErrorMessages } from '../../Actions';
import { errorKey, getToken, setToken } from '../../Actions/utilActions';

function mapStateToProps({ app, user }) {
    return {
        app: app,
        user: user
    };
}

const mapDispatchToProps = dispatch => {
    return {
      clearError: () => {
          dispatch(ClearErrorMessages());
      }
    };
};
class _ErrorPage extends Component {
    static propTypes = {
        user: PropTypes.object,
        history: PropTypes.object
    };
    goHome(){
        if (this.props.user.loggedIn){
            this.props.history.push(this.props.app.loginHome);
        } else {
            this.props.history.push(this.props.app.home);
        }
    }
    componentDidMount(){
        setTimeout(() => {
            if (this.props.app.errorCode !== null){
                setToken(errorKey, JSON.stringify(this.props.app.errorCode));
            }
            this.props.clearError();
        }, 1000);
    }
    render() {
        var error = JSON.parse(getToken(errorKey));
        return (
            <div className="container">
                <div className="nf_container">
                    <div className="form_center">
                        <div className="center margin30">
                            <div className="nf_404">{error?.response?.status}</div>
                            <div className="center margin30">{typeof error?.response?.data === "string" ? error?.response?.data : JSON.stringify(error?.response?.data)}</div>
                            <div className="center pointer margin30" onClick={()=>this.goHome()}>Go to Home</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export const ErrorPage = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_ErrorPage)
);