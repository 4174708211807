// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chk_container{
    display: flex;
    font-size: 14px;
    justify-content: flex-start;
    align-items: center;
}
.chk_input{
    border: 1px solid #e9ddd0;
    background-color: white;
    width: 20px;
    height: 30px;
    padding: 5px 10px;
    font-size: 18px;
    border-radius: 5px;
}
.chk_nput:focus{
    outline: none;
}
.chk_input:disabled{
    background-color: #f2f2f2;
}
.chk_information_text{
    font-weight: bold;
    margin: 0;
    padding: 3px;
}
.chk_error_message{
    border: 1px solid red !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/BasicComponents/CheckBox.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,2BAA2B;IAC3B,mBAAmB;AACvB;AACA;IACI,yBAAyB;IACzB,uBAAuB;IACvB,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,kBAAkB;AACtB;AACA;IACI,aAAa;AACjB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,iBAAiB;IACjB,SAAS;IACT,YAAY;AAChB;AACA;IACI,gCAAgC;AACpC","sourcesContent":[".chk_container{\r\n    display: flex;\r\n    font-size: 14px;\r\n    justify-content: flex-start;\r\n    align-items: center;\r\n}\r\n.chk_input{\r\n    border: 1px solid #e9ddd0;\r\n    background-color: white;\r\n    width: 20px;\r\n    height: 30px;\r\n    padding: 5px 10px;\r\n    font-size: 18px;\r\n    border-radius: 5px;\r\n}\r\n.chk_nput:focus{\r\n    outline: none;\r\n}\r\n.chk_input:disabled{\r\n    background-color: #f2f2f2;\r\n}\r\n.chk_information_text{\r\n    font-weight: bold;\r\n    margin: 0;\r\n    padding: 3px;\r\n}\r\n.chk_error_message{\r\n    border: 1px solid red !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
