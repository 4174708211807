import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './MyPage.css';
import profilePic from "../../Images/user.png";
import { Button, CheckBox, Popup, Select, Tab, Tabs, TextBox } from '../../Components';
import moment from 'moment';
import { getAddress } from '../../Utils/utils';
import i18next from 'i18next';
import { ClearErrorMessages, EditAddress, EditKid, EditSpouse, GetQRCode, UpdateAddressDetails, UpdateKidsDetails, UpdateProfileDetails, UpdateSpouseDetails, UpdateUserEdit, resetPropMessages, setPropMessages } from '../../Actions';
import { validate } from '../../Validation';

const year = "2024";

const input_validator = {
    self: {
        email: ['email', 'mandatory'], firstName: ['alphabetical', 'mandatory'], lastName: ['alphabetical', 'mandatory'], personalNumber: ['personalnumber', 'mandatory']
    },
    kids: {
        firstName: ['alphabetical', 'mandatory'], lastName: ['alphabetical', 'mandatory'], personalNumber: ['personalnumber', 'mandatory']
    },
    address: {
        streetAddress: ['mandatory'], city: ['mandatory'], postalCode: ['number', 'mandatory'], country: ['mandatory']
    }
}

function mapStateToProps({ app, user }) {
    return {
        app: app,
        user: user
    };
}

const mapDispatchToProps = dispatch => {
    return {
        editUser: data => {
            dispatch(UpdateUserEdit(data));
        },
        editAddress: data => {
            dispatch(EditAddress(data));
        },
        editSpouse: data => {
            dispatch(EditSpouse(data));
        },
        editKid: data => {
            dispatch(EditKid(data));
        },
        getQRCode: data => {
            dispatch(GetQRCode(data));
        },
        updateProfile: data => {
            dispatch(UpdateProfileDetails(data));
        },
        updateAddress: data => {
            dispatch(UpdateAddressDetails(data));
        },
        updateSpouse: data => {
            dispatch(UpdateSpouseDetails(data));
        },
        updateKid: data => {
            dispatch(UpdateKidsDetails(data));
        },
        setPropMessages: (key, error) => {
            dispatch(setPropMessages(key, error));
        },
        resetPropMessages: key => {
            dispatch(resetPropMessages(key));
        },
        clearError: () => {
            dispatch(ClearErrorMessages());
        }
    };
};

class _MyPage extends Component {
    static propTypes = {
        user: PropTypes.object,
        history: PropTypes.object
    };
    constructor(props) {
        super(props);
        this.state = {
            renew: false,
            showQRCode: false,
            renewId: []
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.user.QRCODE !== this.props.user.QRCODE && document.getElementById("atspayment_qr_code") != null) {
            document.getElementById("atspayment_qr_code").innerHTML = this.props.user.QRCODE;
        }
    }
    showDetails(data, type) {
        if (type === "date") {
            return moment(data).format("DD-MMMM-yyyy");
        } else if (type === "membership") {
            if (data === "" || data === undefined) {
                return [];
            } else {
                var years = [];
                Object.keys(data).forEach(key => {
                    years.push(key);
                })
                return years;
            }
        } else if (data === "" || data === undefined || data === null) {
            return "Missing";
        } else {
            return data;
        }
    }
    getFamilyMembers() {
        return this.props.user.user?.familyUsers.filter(x => x.id !== this.props.user.user.id);
    }
    initiateEdit() {
        var user = this.props.user.user?.familyUsers.filter(x => x.id === this.props.user.user.id)[0];
        this.props.editUser({ ...user });
    }
    initiateEditAddress() {
        var family = this.props.user.user?.family
        this.props.editAddress({ ...family });
    }
    updateValueOnChange(e) {
        this.props.editUser({ [e.target.name]: e.target.value });
    }
    getPaymentQRCode() {
        var user = this.props.user.user?.familyUsers.filter(x => x.id === this.props.user.user.id)[0];
        var ids = user.givenId;
        this.state.renewId.forEach(x => {
            ids = ids + "," + x;
        });
        this.props.getQRCode(ids);
        this.setState({ showQRCode: true });
    }
    checkRenewId(id) {
        var ids = this.state.renewId;
        if (ids.includes(id)) {
            var index = ids.indexOf(id);
            ids.splice(index, 1);
            this.setState({ renewId: ids });
        } else {
            ids.push(id);
        }
        this.setState({ renewId: ids });
        console.log(ids);
    }
    valid(data, validator) {
        var valid = true;
        Object.keys(validator).forEach(key => {
            const validation = validate(data, key, validator[key]);
            if (!validation.valid) {
                this.props.setPropMessages(key, validation.errors);
                valid = false;
            } else {
                this.props.resetPropMessages(key);
            }
        });
        return valid;
    }
    updateProfile() {
        var valid = true;
        if (this.valid(this.props.user.edit, input_validator.self)) {
            if (valid) {
                console.log("valid for update.");
                this.props.updateProfile({ self: this.props.user.edit });
            } else {
                return;
            }
        }
    }
    updateAddressValue(e) {
        this.props.editAddress({ [e.target.name]: e.target.value });
    }
    updateAddress() {
        var valid = true;
        if (this.valid(this.props.user.editAddress, input_validator.address)) {
            if (valid) {
                this.props.updateAddress({ self: this.props.user.editAddress });
            } else {
                return;
            }
        }
    }
    initiateEditFamily(mem) {
        var user = this.props.user.user?.familyUsers.filter(x => x.id === this.props.user.user.id)[0];
        var spouse = this.props.user.user?.familyUsers.filter(x => x.givenId.endsWith(user.givenId.endsWith('A') ? 'B' : 'A'))[0];
        if (mem.givenId === spouse.givenId) {
            this.initiateEditSpouse(spouse);
        } else {
            this.props.editKid(mem);
        }
    }
    initiateEditSpouse(spouse) {
        this.props.editSpouse(spouse);
    }
    updateSpouseValue(e) {
        this.props.editSpouse({ [e.target.name]: e.target.value });
    }
    updateSpouse() {
        var valid = true;
        if (this.valid(this.props.user.editSpouse, input_validator.self)) {
            if (valid) {
                this.props.updateSpouse({ self: this.props.user.editSpouse });
            } else {
                return;
            }
        }
    }
    updateKidValue(e) {
        this.props.editKid({ [e.target.name]: e.target.value });
    }
    updateKids() {
        var valid = true;
        if (this.valid(this.props.user.editKid, input_validator.kids)) {
            if (valid) {
                this.props.updateKid({ self: this.props.user.editKid });
            } else {
                return;
            }
        }
    }
    addSpouse() {
        this.props.editSpouse({});
    }
    addKid() {
        this.props.editKid({});
    }
    render() {
        var user = this.props.user.user?.familyUsers.filter(x => x.id === this.props.user.user.id)[0];
        if (!user) {
            return null;
        }
        return (
            <div className="register_container">
                <React.Fragment>
                    <div className='profile_page'>
                        <div className='profile_left_container'>
                            <div className="profile_pic_div">
                                <div className='profile_pic_frame'>
                                    <img src={user.pic || profilePic} alt={user.displayName} className="profile_pic"></img>
                                </div>
                            </div>
                        </div>
                        <div className='profile_right_container'>
                            <div className='profile_details'>
                                <div className='profile_tabs_container shadow_style'>
                                    <Tabs>
                                        <Tab name="About">
                                            <div style={{ position: 'relative' }}>
                                                {
                                                    (this.props.user.edit === null && this.props.user.editAddress === null) ?
                                                        <React.Fragment>
                                                            <div style={{ position: 'relative' }}>
                                                                <div className='edit_profile_icon' onClick={() => { this.initiateEdit(); }}>
                                                                    <svg width="1em" height="1em" viewBox="0 0 24 24">
                                                                        <path fill="currentColor" d="m11.4 18.161l7.396-7.396a10.289 10.289 0 0 1-3.326-2.234a10.29 10.29 0 0 1-2.235-3.327L5.839 12.6c-.577.577-.866.866-1.114 1.184a6.556 6.556 0 0 0-.749 1.211c-.173.364-.302.752-.56 1.526l-1.362 4.083a1.06 1.06 0 0 0 1.342 1.342l4.083-1.362c.775-.258 1.162-.387 1.526-.56c.43-.205.836-.456 1.211-.749c.318-.248.607-.537 1.184-1.114m9.448-9.448a3.932 3.932 0 0 0-5.561-5.561l-.887.887l.038.111a8.754 8.754 0 0 0 2.092 3.32a8.754 8.754 0 0 0 3.431 2.13z"></path>
                                                                    </svg>
                                                                </div>
                                                                <div>
                                                                    <div className='personal_details_subsection'>
                                                                        <div className='personal_detail_subtitle'>Basic Information:</div>
                                                                    </div>
                                                                    <div className='personal_details_row'>
                                                                        <div className='personal_detail_title'>Full Name:</div>
                                                                        <div className='personal_detail'>{user.firstName} {user.lastName}</div>
                                                                    </div>
                                                                    <div className='personal_details_row'>
                                                                        <div className='personal_detail_title'>Email:</div>
                                                                        <div className='personal_detail'>{user.email}</div>
                                                                    </div>
                                                                    <div className='personal_details_row'>
                                                                        <div className='personal_detail_title'>Personal Number:</div>
                                                                        <div className='personal_detail'>{this.showDetails(user.personalNumber)}</div>
                                                                    </div>
                                                                    <div className='personal_details_row'>
                                                                        <div className='personal_detail_title'>Date of Birth:</div>
                                                                        <div className='personal_detail'>{this.showDetails(user.personalNumber?.split("-")[0], "date")}</div>
                                                                    </div>
                                                                    <div className='personal_details_row'>
                                                                        <div className='personal_detail_title'>Gender:</div>
                                                                        <div className='personal_detail'>{this.showDetails(user.gender).toUpperCase()}</div>
                                                                    </div>
                                                                    <div className='personal_details_row'>
                                                                        <div className='personal_detail_title'>Phone:</div>
                                                                        <div className='personal_detail'>{this.showDetails(user.phoneNumber)}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{ position: 'relative' }}>
                                                                <div className='edit_profile_icon' onClick={() => { this.initiateEditAddress(); }}>
                                                                    <svg width="1em" height="1em" viewBox="0 0 24 24">
                                                                        <path fill="currentColor" d="m11.4 18.161l7.396-7.396a10.289 10.289 0 0 1-3.326-2.234a10.29 10.29 0 0 1-2.235-3.327L5.839 12.6c-.577.577-.866.866-1.114 1.184a6.556 6.556 0 0 0-.749 1.211c-.173.364-.302.752-.56 1.526l-1.362 4.083a1.06 1.06 0 0 0 1.342 1.342l4.083-1.362c.775-.258 1.162-.387 1.526-.56c.43-.205.836-.456 1.211-.749c.318-.248.607-.537 1.184-1.114m9.448-9.448a3.932 3.932 0 0 0-5.561-5.561l-.887.887l.038.111a8.754 8.754 0 0 0 2.092 3.32a8.754 8.754 0 0 0 3.431 2.13z"></path>
                                                                    </svg>
                                                                </div>
                                                                <div>
                                                                    <div className='personal_details_subsection'>
                                                                        <div className='personal_detail_subtitle'>Address:</div>
                                                                    </div>
                                                                    <div className='personal_details_row'>
                                                                        <div className='personal_detail'>{getAddress(this.props.user.user?.family)}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className='personal_details_subsection'>
                                                                    <div className='personal_detail_subtitle'>Membership Information:</div>
                                                                </div>
                                                                <div className='personal_details_row'>
                                                                    {
                                                                        this.props.user.user?.membership.filter(x => { return x.status === "Approved" }).map((x, i) => {
                                                                            return (
                                                                                <div key={i} className='membershipyear'>
                                                                                    {"FY" + x.year}
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                <div className='personal_details_row'>
                                                                    {
                                                                        this.props.user.user?.membership.filter(x => { return x.year === year && x.status === "Approved" }).length === 0 &&
                                                                        <Button onClick={() => { this.setState({ renew: true, showQRCode: false }) }}>Renew you membership for FY2024</Button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </React.Fragment> :
                                                        <div className='edit_profile_page'>
                                                            {
                                                                this.props.user.edit &&
                                                                <>
                                                                    <div className='register_form_row'>
                                                                        <div className='personal_details_subsection'>
                                                                            <div className='personal_detail_subtitle'>Edit Basic Information:</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='register_form_row'>
                                                                        <div className='register_form_column'>
                                                                            <TextBox name="firstName" type="text" mandatory value={this.props.user.edit.firstName} title={i18next.t('register.fname')} placeholder={i18next.t('register.fname').replace(" *", "")} onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                                                                        </div>
                                                                        <div className='register_form_column'>
                                                                            <TextBox name="lastName" type="text" mandatory value={this.props.user.edit.lastName} title={i18next.t('register.lname')} placeholder={i18next.t('register.lname').replace(" *", "")} onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                                                                        </div>
                                                                        <div className='register_form_column'>
                                                                            <TextBox name="personalNumber" type="text" mandatory value={this.props.user.edit.personalNumber} title={i18next.t('register.personalnumber')} placeholder="yyyymmdd-xxxx" onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                                                                        </div>
                                                                        <div className='register_form_column'>
                                                                            <TextBox name="email" type="text" mandatory value={this.props.user.edit.email} title={i18next.t('register.email')} placeholder={i18next.t('register.email').replace(" *", "")} onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                                                                        </div>
                                                                    </div>
                                                                    <div className='register_form_row'>
                                                                        <div className='register_form_column'>
                                                                            <Select name="gender" value={this.props.user.edit.gender.toLowerCase()} title={i18next.t('register.gender')} options={[{ text: '--Select--', value: '' }, { text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }]} onChange={(e) => this.updateValueOnChange(e)}></Select>
                                                                        </div>
                                                                        <div className='register_form_column'>
                                                                            <TextBox name="phoneNumber" type="text" value={this.props.user.edit.phoneNumber} title={i18next.t('register.phonenumber')} placeholder={i18next.t('register.phonenumber')} onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                                                                        </div>
                                                                    </div>
                                                                    <div className='register_form_row_single_center'>
                                                                        <div className='register_form_column_single_center'>
                                                                            <Button onClick={() => this.updateProfile()}>{i18next.t('register.save')}</Button>
                                                                            <Button onClick={() => this.props.editUser(null)}>{i18next.t('register.cancel')}</Button>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {
                                                                this.props.user.editAddress &&
                                                                <>
                                                                    <div className='register_form_row'>
                                                                        <div className='personal_details_subsection'>
                                                                            <div className='personal_detail_subtitle'>Edit Address Information:</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='register_form_row'>
                                                                        <div className='register_form_column'>
                                                                            <TextBox name="streetAddress" type="text" value={this.props.user.editAddress.streetAddress} title={i18next.t('register.streetaddress')} placeholder={i18next.t('register.streetaddress')} onChange={(e) => this.updateAddressValue(e)}></TextBox>
                                                                        </div>
                                                                        <div className='register_form_column'>
                                                                            <TextBox name="city" type="text" value={this.props.user.editAddress.city} title={i18next.t('register.city')} placeholder={i18next.t('register.city')} onChange={(e) => this.updateAddressValue(e)}></TextBox>
                                                                        </div>
                                                                    </div>
                                                                    <div className='register_form_row'>
                                                                        <div className='register_form_column'>
                                                                            <TextBox name="postalCode" type="text" value={this.props.user.editAddress.postalCode} title={i18next.t('register.postalcode')} placeholder={i18next.t('register.postalcode')} onChange={(e) => this.updateAddressValue(e)}></TextBox>
                                                                        </div>
                                                                        <div className='register_form_column'>
                                                                            <TextBox name="country" type="text" value={this.props.user.editAddress.country} title={i18next.t('register.country')} placeholder={i18next.t('register.country')} onChange={(e) => this.updateAddressValue(e)}></TextBox>
                                                                        </div>
                                                                    </div>
                                                                    <div className='register_form_row_single_center'>
                                                                        <div className='register_form_column_single_center'>
                                                                            <Button onClick={() => this.updateAddress()}>{i18next.t('register.save')}</Button>
                                                                            <Button onClick={() => this.props.editAddress(null)}>{i18next.t('register.cancel')}</Button>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                }
                                            </div>
                                        </Tab>
                                        <Tab name="Family">
                                            <div className='family_container'>
                                                {
                                                    (this.props.user.user?.familyUsers.length === 1 && this.props.user.editSpouse === null && this.props.user.editKid === null) &&
                                                    <div>
                                                        <Button onClick={() => this.addSpouse()}>{i18next.t('register.addnewspouse')}</Button>
                                                    </div>
                                                }
                                                {
                                                    (this.props.user.editSpouse === null && this.props.user.editKid === null) &&
                                                    this.getFamilyMembers().map((mem, i) => {
                                                        return (
                                                            <div key={i} className='familyMembers'>
                                                                <div className='edit_profile_icon' onClick={() => { this.initiateEditFamily(mem); }}>
                                                                    <svg width="1em" height="1em" viewBox="0 0 24 24">
                                                                        <path fill="currentColor" d="m11.4 18.161l7.396-7.396a10.289 10.289 0 0 1-3.326-2.234a10.29 10.29 0 0 1-2.235-3.327L5.839 12.6c-.577.577-.866.866-1.114 1.184a6.556 6.556 0 0 0-.749 1.211c-.173.364-.302.752-.56 1.526l-1.362 4.083a1.06 1.06 0 0 0 1.342 1.342l4.083-1.362c.775-.258 1.162-.387 1.526-.56c.43-.205.836-.456 1.211-.749c.318-.248.607-.537 1.184-1.114m9.448-9.448a3.932 3.932 0 0 0-5.561-5.561l-.887.887l.038.111a8.754 8.754 0 0 0 2.092 3.32a8.754 8.754 0 0 0 3.431 2.13z"></path>
                                                                    </svg>
                                                                </div>
                                                                <div className='personal_details_row'>
                                                                    <div className='profile_user_name'>{mem.firstName} {mem.lastName}</div>
                                                                </div>
                                                                <div className='personal_details_row'>
                                                                    <div className='profile_user_email'>{mem.email}</div>
                                                                </div>
                                                                <div className='personal_details_row'>
                                                                    <div className='profile_user_email'>{mem.personalNumber}</div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {
                                                    this.props.user.editSpouse &&
                                                    <>
                                                        <div className='register_form_row'>
                                                            <div className='personal_details_subsection'>
                                                                <div className='personal_detail_subtitle'>{this.props.user.editSpouse.id === undefined ? "Add" : "Edit"} Spouse's Basic Information:</div>
                                                            </div>
                                                        </div>
                                                        <div className='register_form_row'>
                                                            <div className='register_form_column'>
                                                                <TextBox name="firstName" type="text" mandatory value={this.props.user.editSpouse.firstName} title={i18next.t('register.fname')} placeholder={i18next.t('register.fname').replace(" *", "")} onChange={(e) => this.updateSpouseValue(e)}></TextBox>
                                                            </div>
                                                            <div className='register_form_column'>
                                                                <TextBox name="lastName" type="text" mandatory value={this.props.user.editSpouse.lastName} title={i18next.t('register.lname')} placeholder={i18next.t('register.lname').replace(" *", "")} onChange={(e) => this.updateSpouseValue(e)}></TextBox>
                                                            </div>
                                                            <div className='register_form_column'>
                                                                <TextBox name="personalNumber" type="text" mandatory value={this.props.user.editSpouse.personalNumber} title={i18next.t('register.personalnumber')} placeholder="yyyymmdd-xxxx" onChange={(e) => this.updateSpouseValue(e)}></TextBox>
                                                            </div>
                                                            <div className='register_form_column'>
                                                                <TextBox name="email" type="text" mandatory value={this.props.user.editSpouse.email} title={i18next.t('register.email')} placeholder={i18next.t('register.email').replace(" *", "")} onChange={(e) => this.updateSpouseValue(e)}></TextBox>
                                                            </div>
                                                        </div>
                                                        <div className='register_form_row'>
                                                            <div className='register_form_column'>
                                                                <Select name="gender" value={this.props.user.editSpouse.gender} title={i18next.t('register.gender')} options={[{ text: '--Select--', value: '' }, { text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }]} onChange={(e) => this.updateSpouseValue(e)}></Select>
                                                            </div>
                                                            <div className='register_form_column'>
                                                                <TextBox name="phoneNumber" type="text" value={this.props.user.editSpouse.phoneNumber} title={i18next.t('register.phonenumber')} placeholder={i18next.t('register.phonenumber')} onChange={(e) => this.updateSpouseValue(e)}></TextBox>
                                                            </div>
                                                        </div>
                                                        <div className='register_form_row_single_center'>
                                                            <div className='register_form_column_single_center'>
                                                                <Button onClick={() => this.updateSpouse()}>{i18next.t('register.save')}</Button>
                                                                <Button onClick={() => this.props.editSpouse(null)}>{i18next.t('register.cancel')}</Button>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    this.props.user.editKid === null &&
                                                    <div>
                                                        <Button onClick={() => this.addKid()}>{i18next.t('register.addnewkid')}</Button>
                                                    </div>
                                                }
                                                {
                                                    this.props.user.editKid &&
                                                    <>
                                                        <div className='register_form_row'>
                                                            <div className='personal_details_subsection'>
                                                                <div className='personal_detail_subtitle'>{this.props.user.editKid.id === undefined ? "Add" : "Edit"} Kid's Basic Information:</div>
                                                            </div>
                                                        </div>
                                                        <div className='register_form_row'>
                                                            <div className='register_form_column'>
                                                                <TextBox name="firstName" type="text" mandatory value={this.props.user.editKid.firstName} title={i18next.t('register.fname')} placeholder={i18next.t('register.fname').replace(" *", "")} onChange={(e) => this.updateKidValue(e)}></TextBox>
                                                            </div>
                                                            <div className='register_form_column'>
                                                                <TextBox name="lastName" type="text" mandatory value={this.props.user.editKid.lastName} title={i18next.t('register.lname')} placeholder={i18next.t('register.lname').replace(" *", "")} onChange={(e) => this.updateKidValue(e)}></TextBox>
                                                            </div>
                                                            <div className='register_form_column'>
                                                                <TextBox name="personalNumber" type="text" mandatory value={this.props.user.editKid.personalNumber} title={i18next.t('register.personalnumber')} placeholder="yyyymmdd-xxxx" onChange={(e) => this.updateKidValue(e)}></TextBox>
                                                            </div>
                                                        </div>
                                                        <div className='register_form_row_single_center'>
                                                            <div className='register_form_column_single_center'>
                                                                <Button onClick={() => this.updateKids()}>{i18next.t('register.save')}</Button>
                                                                <Button onClick={() => this.props.editKid(null)}>{i18next.t('register.cancel')}</Button>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Popup open={this.state.renew} closePopup={() => { this.props.clearError();this.setState({ renew: false }) }}>
                        {
                            this.props.app.error_alert ?
                                <React.Fragment>
                                    {
                                        this.props.app.error_alert?.message.split('\n').map(function( item, idx) {
                                            return (
                                                <span key={idx}>
                                                  {item}
                                                  <br/>
                                                  <br/>
                                                </span>
                                            )
                                          })
                                    }
                                </React.Fragment> :
                                <React.Fragment>

                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10, fontWeight: 'bold' }}>{!this.state.showQRCode ? "Select family members you want to renew?" : "Use the below QR code and complete your Payment."}</div>                        <>
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <CheckBox name="self" value={true} disabled={true}></CheckBox> {user.firstName} {user.lastName}
                                        </div>
                                        {
                                            this.props.user.user?.familyUsers.filter(x => x.id !== this.props.user.user.id).map((x, i) => {
                                                return (
                                                    <div key={i} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                        <CheckBox value={this.state.renewId.includes(x.givenId)} name={x.givenId} onChange={(e) => { this.checkRenewId(x.givenId) }} disabled={this.state.showQRCode}></CheckBox> {x.firstName} {x.lastName}
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                    {
                                        !this.state.showQRCode &&
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                            <Button onClick={() => { this.getPaymentQRCode() }}>{i18next.t('register.proceed')}</Button>
                                        </div>
                                    }
                                    {
                                        this.props.user.QRCODE &&
                                        <div>
                                            <div id={"atspayment_qr_code"}>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                                <Button onClick={() => { this.setState({ renew: false, showQRCode: false }); this.props.clearError(); }}>{i18next.t('register.paymentcompleted')}</Button>
                                            </div>
                                        </div>
                                    }
                                </React.Fragment>
                        }
                    </Popup>
                </React.Fragment>
            </div>
        );
    }
}

export const MyPage = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_MyPage)
);