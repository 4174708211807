// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.txt_area{
    border: 1px solid #e9ddd0;
    background-color: white;
    width: 100%;
    min-height: 150px;
    padding: 5px 10px;
    font-size: 18px;
    border-radius: 5px;
    width: calc(100% - 20px);
}
.txt_area:focus{
    outline: none;
}
.txt_area:disabled{
    background-color: #f2f2f2;
}`, "",{"version":3,"sources":["webpack://./src/Components/BasicComponents/TextArea.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,uBAAuB;IACvB,WAAW;IACX,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,wBAAwB;AAC5B;AACA;IACI,aAAa;AACjB;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".txt_area{\r\n    border: 1px solid #e9ddd0;\r\n    background-color: white;\r\n    width: 100%;\r\n    min-height: 150px;\r\n    padding: 5px 10px;\r\n    font-size: 18px;\r\n    border-radius: 5px;\r\n    width: calc(100% - 20px);\r\n}\r\n.txt_area:focus{\r\n    outline: none;\r\n}\r\n.txt_area:disabled{\r\n    background-color: #f2f2f2;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
