import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Popup.css';
import { Icon } from './Icon';

export class Popup extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    text: PropTypes.string,
    closePopup: PropTypes.func,
    children: PropTypes.any,
    outsideClickClose: PropTypes.bool,
    noClose: PropTypes.bool
  };
  menuItemClick = () =>{
    this.props.closePopup();
  }
  render() {
    if (this.props.open){
      return (
        <div className="pop_popup" style={{display: (this.props.open ? 'contents' : 'none')}}>
          <div className={classNames("pop_popup_background", this.props.className)} style={{ zIndex: this.props.level === "top" ? 99 : 9}} onClick={() => {this.props.outsideClickClose && this.menuItemClick()}}></div>
          <div className={classNames("pop_popup_body", this.props.noClose && "nowidth")} style={{ zIndex: this.props.level === "top" ? 100 : 10}}>
            {
              !this.props.noClose && 
              <div className="pop_popup_title">
                <div className="pop_popup_close_icon" onClick={() => this.menuItemClick()}>
                  <Icon>{'close'}</Icon>
                </div>
              </div>
            }
            <div>
              {this.props.children}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
