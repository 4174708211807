import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './MembershipRegistrationPage.css';
import { validate } from '../../Validation';
import { OpenPopup, RegisterMembership, resetPropMessages, setPropMessages, UpdateUserRegisterValue } from '../../Actions';
import { Button, CheckBox, Icon, Select, TextBox } from '../../Components';
import i18next from 'i18next';

const acceptVersion = "v1.0.0";

const input_validator = {
    self: {
        email: ['email', 'mandatory'], firstname: ['alphabetical', 'mandatory'], lastname: ['alphabetical', 'mandatory'],
        personalnumber: ['personalnumber', 'mandatory'], accept: ['accept']
    },
    spouse: {
        spouseemail: ['email', 'mandatory'], spousefirstname: ['alphabetical', 'mandatory'], spouselastname: ['alphabetical', 'mandatory'],
        spousepersonalnumber: ['personalnumber', 'mandatory']
    },
    kids: {
        kidfirstname: ['alphabetical', 'mandatory'], kidlastname: ['alphabetical', 'mandatory'], kidpersonalnumber: ['personalnumber', 'mandatory']
    }
}

function mapStateToProps({ app, user }) {
    return { app, user };
}

const mapDispatchToProps = dispatch => {
    return {
        openPopup: data => {
            dispatch(OpenPopup(data));
        },
        updateValue: data => {
            dispatch(UpdateUserRegisterValue(data));
        },
        setPropMessages: (key, error) => {
            dispatch(setPropMessages(key, error));
        },
        resetPropMessages: key => {
            dispatch(resetPropMessages(key));
        },
        registerMembership: data => {
            dispatch(RegisterMembership(data));
        }
    };
};

class _MembershipRegistrationPage extends Component {
    static propTypes = {
        user: PropTypes.object,
        location: PropTypes.object,
        history: PropTypes.object
    };
    constructor(props) {
        super(props);
        this.state = {
            accept: false,
            spouse: false,
            kids: false,
            kidsCount: 0
        }
    }
    valid(data, validator) {
        var valid = true;
        Object.keys(validator).forEach(key => {
            const validation = validate(data, key, validator[key]);
            if (!validation.valid) {
                this.props.setPropMessages(key, validation.errors);
                valid = false;
            } else {
                this.props.resetPropMessages(key);
            }
        });
        return valid;
    }
    updateValueOnChange(e) {
        this.props.updateValue({ [e.target.name]: e.target.value });
    }
    getKidsValidator(index) {
        var val = {};
        Object.keys(input_validator.kids).forEach(key => {
            val[key + index] = input_validator.kids[key];
        })
        return val;
    }
    async register(e) {
        e.preventDefault();
        e.stopPropagation();
        var valid = true;
        if (this.state.spouse && !this.valid(this.props.user.register, input_validator.spouse)) {
            valid = false;
        }
        if (this.state.kids) {
            this.createArrayOfSize(this.state.kidsCount).forEach((x, i) => {
                if (!this.valid(this.props.user.register, this.getKidsValidator(i))) {
                    valid = false;
                }
            })
        }
        if (this.valid(this.props.user.register, input_validator.self)) {
            if (valid) {
                this.props.registerMembership(this.createFormDetails());
            } else {
                return;
            }
        }
    }
    createFormDetails() {
        var form = {};
        var self = {
            firstName: this.props.user.register.firstname,
            lastName: this.props.user.register.lastname,
            personalNumber: this.props.user.register.personalnumber,
            gender: this.props.user.register.gender,
            email: this.props.user.register.email
        };
        if (this.props.user.register.streetaddress) self.streetAddress = this.props.user.register.streetaddress;
        if (this.props.user.register.city) self.city = this.props.user.register.city;
        if (this.props.user.register.postalcode) self.postalCode = this.props.user.register.postalcode;
        if (this.props.user.register.country) self.country = this.props.user.register.country;
        if (this.props.user.register.phone) self.phoneNumber = this.props.user.register.phone;
        self.accept = acceptVersion;
        form["self"] = self;
        if (this.state.spouse) {
            var spouse = {
                firstName: this.props.user.register.spousefirstname,
                lastName: this.props.user.register.spouselastname,
                personalNumber: this.props.user.register.spousepersonalnumber,
                email: this.props.user.register.spouseemail,
            }
            if (this.props.user.register.spousephone) spouse.phoneNumber = this.props.user.register.spousephone;
            form["spouse"] = spouse;
        }
        if (this.state.kids) {
            var kids = [];
            this.createArrayOfSize(this.state.kidsCount).forEach((x, i) => {
                var kid = {
                    firstName: this.props.user.register["kidfirstname" + i],
                    lastName: this.props.user.register["kidlastname" + i],
                    personalNumber: this.props.user.register["kidpersonalnumber" + i],
                };
                kids.push(kid);
            })
            form["kids"] = kids;
        }
        return form;
    }
    createArrayOfSize(size) {
        var array = [];
        for (var i = 0; i < size; i++) {
            array.push(i);
        }
        return array;
    }
    removeKids() {
        if (this.state.kidsCount > 1) {
            this.setState({ kidsCount: this.state.kidsCount - 1 });
        } else {
            this.setState({ kidsCount: 0, kids: false });
        }
    }
    render() {
        if (this.props.user.register.submit) {
            return (
                <div className="register_container">
                    <div className='register_page_header'>
                        <h3>{i18next.t('register.pagetitle')}</h3>
                    </div>
                    {
                        this.props.user.register.submit === "success" ?
                        <div className='register_form_result register_form_success'>
                            You have successfully submited your registration. <br/> 
                            We have sent you an email for membership fee payment details. <br/>
                            Once you paid It will take one or two days to get it approved. You will get a notification in mail after the approval.
                        </div>:
                        <div className='register_form_result register_form_fail'>
                            Something went wrong during the registration process. Please try again. <br/> If the issue persists, contact the board members for assistance.
                        </div>
                    }
                </div>
            )
        } else {
            return (
                <div className="register_container">
                    <div className='register_page_header'>
                        <h3>{i18next.t('register.pagetitle')}</h3>
                    </div>
                    <div className='register_form'>
                        <div className='register_form_row'>
                            <div className='register_form_column'>
                                <TextBox name="firstname" type="text" mandatory value={this.props.user.register.firstname} title={i18next.t('register.fname')} placeholder={i18next.t('register.fname').replace(" *", "")} onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                            </div>
                            <div className='register_form_column'>
                                <TextBox name="lastname" type="text" mandatory value={this.props.user.register.lastname} title={i18next.t('register.lname')} placeholder={i18next.t('register.lname').replace(" *", "")} onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                            </div>
                            <div className='register_form_column'>
                                <TextBox name="personalnumber" type="text" mandatory value={this.props.user.register.personalnumber} title={i18next.t('register.personalnumber')} placeholder="yyyymmdd-xxxx" onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                            </div>
                        </div>
                        <div className='register_form_row'>
                            <div className='register_form_column'>
                                <Select name="gender" value={this.props.user.register.gender} title={i18next.t('register.gender')} options={[{ text: '--Select--', value: '' }, { text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }]} onChange={(e) => this.updateValueOnChange(e)}></Select>
                            </div>
                        </div>
                        <div className='register_form_row'>
                            <div className='register_form_column'>
                                <TextBox name="streetaddress" type="text" value={this.props.user.register.streetaddress} title={i18next.t('register.streetaddress')} placeholder={i18next.t('register.streetaddress')} onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                            </div>
                            <div className='register_form_column'>
                                <TextBox name="city" type="text" value={this.props.user.register.city} title={i18next.t('register.city')} placeholder={i18next.t('register.city')} onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                            </div>
                        </div>
                        <div className='register_form_row'>
                            <div className='register_form_column'>
                                <TextBox name="postalcode" type="text" value={this.props.user.register.postalcode} title={i18next.t('register.postalcode')} placeholder={i18next.t('register.postalcode')} onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                            </div>
                            <div className='register_form_column'>
                                <TextBox name="country" type="text" value={this.props.user.register.country} title={i18next.t('register.country')} placeholder={i18next.t('register.country')} onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                            </div>
                        </div>
                        <div className='register_form_row'>
                            <div className='register_form_column'>
                                <TextBox name="email" type="text" mandatory value={this.props.user.register.email} title={i18next.t('register.email')} placeholder={i18next.t('register.email').replace(" *", "")} onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                            </div>
                            <div className='register_form_column'>
                                <TextBox name="phone" type="text" value={this.props.user.register.phone} title={i18next.t('register.phonenumber')} placeholder={i18next.t('register.phonenumber')} onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                            </div>
                        </div>
                        <hr className="line_devider"></hr>
                        <div className='register_form_row'>
                            <div className='register_form_column'>
                                <CheckBox name="addspouse" value={this.state.spouse} title={i18next.t('register.addspouse')} onChange={(e) => this.setState({ spouse: !this.state.spouse })}>{i18next.t('register.addspouse')}</CheckBox>
                            </div>
                        </div>
                        {
                            this.state.spouse &&
                            <>
                                <div className='register_form_row'>
                                    <div className='register_form_column'>
                                        <TextBox name="spousefirstname" type="text" mandatory value={this.props.user.register.spousefirstname} title={`${i18next.t('register.spouse')} ${i18next.t('register.fname')}`} placeholder={i18next.t('register.fname').replace(" *", "")} onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                                    </div>
                                    <div className='register_form_column'>
                                        <TextBox name="spouselastname" type="text" mandatory value={this.props.user.register.spouselastname} title={`${i18next.t('register.spouse')} ${i18next.t('register.lname')}`} placeholder={i18next.t('register.lname').replace(" *", "")} onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                                    </div>
                                    <div className='register_form_column'>
                                        <TextBox name="spousepersonalnumber" type="text" mandatory value={this.props.user.register.spousepersonalnumber} title={`${i18next.t('register.spouse')} ${i18next.t('register.personalnumber')}`} placeholder="yyyymmdd-xxxx" onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                                    </div>
                                </div>
                                <div className='register_form_row'>
                                    <div className='register_form_column'>
                                        <TextBox name="spouseemail" type="text" mandatory value={this.props.user.register.spouseemail} title={i18next.t('register.email')} placeholder={i18next.t('register.email').replace(" *", "")} onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                                    </div>
                                    <div className='register_form_column'>
                                        <TextBox name="spousephone" type="text" value={this.props.user.register.spousephone} title={i18next.t('register.phonenumber')} placeholder={i18next.t('register.phonenumber')} onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                                    </div>
                                </div>
                            </>
                        }
                        <hr className="line_devider"></hr>
                        <div className='register_form_row'>
                            <div className='register_form_column'>
                                <CheckBox name="addkids" value={this.state.kids} title={i18next.t('register.addkids')} onChange={(e) => this.setState({ kids: !this.state.kids, kidsCount: this.state.kidsCount === 0 ? 1 : this.state.kidsCount })}>
                                    {i18next.t('register.addkids')}
                                    {
                                        this.state.kids &&
                                        <>
                                            <button className="addkids" type="button" onClick={() => this.setState({ kidsCount: this.state.kidsCount < 5 ? this.state.kidsCount + 1 : this.state.kidsCount })}>
                                                <span className="MuiButton-startIcon MuiButton-iconSizeSmall css-16rzsu1">
                                                    <svg role="img" className="addkidssvg" width="1em" height="1em" viewBox="0 0 24 24">
                                                        <g fill="none">
                                                            <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
                                                            <path fill="currentColor" d="M11 20a1 1 0 1 0 2 0v-7h7a1 1 0 1 0 0-2h-7V4a1 1 0 1 0-2 0v7H4a1 1 0 1 0 0 2h7z"></path>
                                                        </g>
                                                    </svg>
                                                </span>
                                                Add
                                            </button>
                                            <button className='removekids' onClick={() => { this.removeKids() }}>
                                                <span className="MuiButton-startIcon MuiButton-iconSizeSmall css-16rzsu1">
                                                    <svg role="img" className="removekidssvg" width="1em" height="1em" viewBox="0 0 24 24">
                                                        <path fill="rgb(255, 86, 48)" d="M3 6.386c0-.484.345-.877.771-.877h2.665c.529-.016.996-.399 1.176-.965l.03-.1l.115-.391c.07-.24.131-.45.217-.637c.338-.739.964-1.252 1.687-1.383c.184-.033.378-.033.6-.033h3.478c.223 0 .417 0 .6.033c.723.131 1.35.644 1.687 1.383c.086.187.147.396.218.637l.114.391l.03.1c.18.566.74.95 1.27.965h2.57c.427 0 .772.393.772.877s-.345.877-.771.877H3.77c-.425 0-.77-.393-.77-.877"></path>
                                                        <path fill="rgb(255, 86, 48)" fillRule="evenodd" d="M11.596 22h.808c2.783 0 4.174 0 5.08-.886c.904-.886.996-2.339 1.181-5.245l.267-4.188c.1-1.577.15-2.366-.303-2.865c-.454-.5-1.22-.5-2.753-.5H8.124c-1.533 0-2.3 0-2.753.5c-.454.5-.404 1.288-.303 2.865l.267 4.188c.185 2.906.277 4.36 1.182 5.245c.905.886 2.296.886 5.079.886m-1.35-9.811c-.04-.434-.408-.75-.82-.707c-.413.043-.713.43-.672.864l.5 5.263c.04.434.408.75.82.707c.413-.043.713-.43.672-.864zm4.329-.707c.412.043.713.43.671.864l-.5 5.263c-.04.434-.409.75-.82.707c-.413-.043-.713-.43-.672-.864l.5-5.263c.04-.434.409-.75.82-.707" clipRule="evenodd"></path>
                                                    </svg>
                                                </span>
                                                Remove
                                            </button>
                                        </>
                                    }
                                </CheckBox>
                            </div>
                        </div>
                        {
                            this.state.kids &&
                            <>
                            {
                                this.createArrayOfSize(this.state.kidsCount).map((x, i) => {
                                    return (
                                        <div className='register_form_row' key={i}>
                                            <div className='register_form_column'>
                                                <TextBox name={"kidfirstname" + i} type="text" mandatory value={this.props.user.register["kidfirstname" + i]} title={`${i + 1}. ${i18next.t('register.kid')} ${i18next.t('register.fname')}`} placeholder={i18next.t('register.fname').replace(" *", "")} onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                                            </div>
                                            <div className='register_form_column'>
                                                <TextBox name={"kidlastname" + i} type="text" mandatory value={this.props.user.register["kidlastname" + i]} title={`${i18next.t('register.kid')} ${i18next.t('register.lname')}`} placeholder={i18next.t('register.lname').replace(" *", "")} onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                                            </div>
                                            <div className='register_form_column'>
                                                <TextBox name={"kidpersonalnumber" + i} type="text" mandatory value={this.props.user.register["kidpersonalnumber" + i]} title={`${i18next.t('register.kid')} ${i18next.t('register.personalnumber')}`} placeholder="yyyymmdd-xxxx" onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                                <p className='register_form_kids_info'>
                                    Max 5 can be mentioned. If you have kids more than 5 count then please reach out to almhult.tamilsangam@gmail.com.
                                </p>
                            </>
                        }
                        <hr className="line_devider"></hr>
                        <div className='register_form_row'>
                            <div className='register_form_column'>
                                <CheckBox name="accept" mandatory value={this.props.user.register.accept} title={i18next.t('register.termsconditions')} onChange={(e) => { if (this.props.user.register.accept) { this.updateValueOnChange({ target: { name: "accept", value: false } }); } else {this.setState({ accept: !this.state.accept }); window.scrollTo({ top: 0, behavior: 'smooth' });} }}>{i18next.t('register.termsconditions')} {i18next.t('register.tearmslink')}</CheckBox>
                            </div>
                        </div>
                        {
                            this.props.user.register.reason &&
                            <>
                                <hr className="line_devider"></hr>
                                <div className='register_form_row'>
                                    <div className='register_form_column error_message'>
                                        {this.props.user.register.reason}
                                    </div>
                                </div>
                            </>
                        }
                        <div className='register_form_row_single_center'>
                            <div className='register_form_column_szingle_center'>
                                <Button onClick={(e) => this.register(e)}>{i18next.t('register.register')}</Button>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.accept &&
                        <div className='termsandcondition_popup_back'>
                            <div className='register_container termsandcondition_popup' style={{maxHeight: '80%', overflow: 'scroll'}}>
                                <div className='popup_close_btn' onClick={()=>{this.setState({ accept: false })}}>
                                        <Icon>close</Icon>
                                </div>
                                <div className='termsandcondition_header'>
                                    <h3>{i18next.t('register.tearmslink')}</h3>
                                </div>
                                <div className='termsandcondition'>
                                    1. Membership is available to anyone 18 years of age or older.<br />
                                    2. Membership Fee is 100 SEK per adult person above 18 years, 50 SEK for kids between age 6 to 18, and free for kids below age 6.<br />
                                    &emsp;    (i)   Payment details will be sent to your email after submitting the form.<br />
                                    &emsp;    (ii)   We accept payment either in swish/bank transfer mode only.<br />
                                    3. Payment received by ÄTS cannot be refunded for any reason.<br />
                                    &emsp;    (i)   Membership amount received is non-refundable.<br />
                                    &emsp;   (ii)  Any event related cost received is non-refundable.<br />
                                    &emsp;    (iii)  Membership is non-transferrable to any other association.<br />
                                    <h3>Privacy Policy *</h3>
                                    <p>ÄTS reserves the right to verify the information provided in the Membership form. In accordance with and without limitation to ÄTS bylaws, If ÄTS determines, in its sole discretion, that any individual on the membership application form no longer subscribes to the objectives of ÄTS or that false or misleading information was provided, ÄTS may reject this application or cancel the membership of any or all persons listed in this application form. In any case, membership fees are non-refundable.</p>

                                    <h3>GDPR Compliance *</h3>
                                    <p>
                                    
To comply with the requirements of the EU General Data Protection Regulation (EU 2016/679) (the “GDPR”), we have made some changes to ÄTS Members Data handling Policy which will go into effect from 1st April 2022.<br /><br />

Here are the changes we are making to the policy:<br /><br />

Permanently deleting information from ÄTS domain:<br />
While you have always been able to withdraw previously given consent for our information flow by unsubscribing from our email group, Whatsapp group and Facebook group, you will also be able to request the deletion of your personal data(Emails, Phone Numbers, Address, Photos) and we’ll make sure your personal data is permanently removed from our records.<br /><br />

Please do not hesitate to contact almhult.tamilsangam@gmail.com if you have any concerns related to your personal data handled by ÄTS.
                                    </p>
                                </div>
                                <div className='register_form_column_single_center'>
                                    <Button onClick={() => { this.updateValueOnChange({ target: { name: "accept", value: true } }); this.setState({ accept: !this.state.accept }); }}>{i18next.t('register.accept')}</Button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            );
        }
    }
}

export const MembershipRegistrationPage = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_MembershipRegistrationPage)
);