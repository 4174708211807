import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LogoutUser } from '../Actions';

function mapStateToProps({ app, user }) {
    return { app, user };
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        logout: () =>{
            dispatch(LogoutUser());
        }
    };
  };
  
class _Logout extends Component {
    componentDidMount(){
        this.props.logout();
    }
    componentDidUpdate(){
        if (this.props.user.user !== null){
            this.props.history.push("/mypage");
        }
        if (this.props.user.user === null){
            this.props.history.push("/");
        }
    }
    render() {
        return <div>Logging out...</div>; 
    }
}
export const Logout = withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(_Logout)
  );