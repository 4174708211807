import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './VerifyLinkPage.css';
// import { VerifyUserEmail } from '../../Actions';

function mapStateToProps({ app, user }) {
    return {
        app: app,
        user: user
    };
}

const mapDispatchToProps = dispatch => {
    return {
        // verifyLink: code => {
        //     dispatch(VerifyUserEmail(code));
        // },
    };
};

class _VerifyLinkPage extends Component {
    static propTypes = {
        user: PropTypes.object,
        history: PropTypes.object
    };
    componentDidMount(){
        this.props.verifyLink(new URLSearchParams(this.props.location.search).get("oobCode"));
    }
    componentDidUpdate(){
        if (this.props.user.verifyEmail === "Pass"){
            this.props.history.push("/members?pass")
        }
        if (this.props.user.verifyEmail === "Fail"){
            this.props.history.push("/members?fail")
        }
    }
    render() {
        return (
            <div className="container">
                <div className="nf_container">
                    <div className="form_center">
                        <div className="center margin30">
                            <div className="nf_404">Email Verification!!</div>
                            <div className="center margin30">Please wait while we are verifying your email address.</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export const VerifyLinkPage = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_VerifyLinkPage)
);