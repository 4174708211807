import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './Footer.css';
import i18next from 'i18next';
import { Icon } from '../BasicComponents/Icon';

function mapStateToProps({ app, user }) {
    return {
        app: app,
        user: user
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

class _Footer extends Component {
    static propTypes = {
        user: PropTypes.object,
        history: PropTypes.object
    };
    render() {
        return (
            <div className="footer_container">
                <div className="social_row">
                    <div className="social_details">
                        <div className="social_media_icon" onClick={()=>{window.open('https://www.facebook.com/groups/2175632599366735','_blank')}}>
                            <Icon>facebook</Icon>
                        </div>
                        {/* <div className="social_media_icon" onClick={()=>{}}>
                            <Icon>twitter</Icon>
                        </div> */}
                        <div className="social_media_icon" onClick={()=>{window.open('https://www.instagram.com/almhult_tamil_sangam/', '_blank')}}>
                            <Icon>instagram</Icon>
                        </div>
                    </div>
                </div>
                <div className="contact_details_row">
                    <div className="contact_details">
                        <div className='contact_details_column'>
                            <div className='contact_link underline'>{i18next.t('menu.contactus')}</div>
                            <div className='contact_link'>{i18next.t('footer.mobileNumberName')} {i18next.t('footer.mobileNumber')}</div>
                            <div className='contact_link'>{i18next.t('footer.emailName')} {i18next.t('footer.email')}</div>
                            <div className='contact_link'>{i18next.t('footer.addressName')} {i18next.t('footer.address')}</div>
                        </div>
                        <div className='contact_details_column'>
                        </div>
                        <div className='contact_details_column'>
                            <div className='contact_link'>{i18next.t('footer.orgName')} {i18next.t('footer.orgNo')}</div>
                        </div>
                        {/* <div className='contact_details_column'>
                            <div className='contact_link clickable' onClick={()=>this.props.history.push("/aboutus")}>{i18next.t('menu.aboutus')}</div>
                        </div> */}
                    </div>
                </div>
                <div className='copy_right_row'>
                    <div className='copy_right'>
                        {i18next.t('footer.copyright')}
                    </div>
                </div>
            </div>
        );
    }
}

export const Footer = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_Footer)
);