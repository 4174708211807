// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dd_container{
  width: 320px;
    display: block;
    font-size: 14px;
    position: relative;
}

.dd_information_text{
  margin: 0;
  padding: 3px;
}
.dd_select{
  width: 100%;
  background: transparent;
  -webkit-appearance: none;
  padding-right: 30px;
  border: none;
  padding: 15px;
  border: 1px solid #e9ddd0;
  background-color: white;
  height: 36px;
  padding: 0 10px;
  font-size: 18px;
}
.dd_select::-ms-expand {
    display: none;
}
.dd_icon{
  position: absolute;
  right: 6px;
  top: calc(50% - 2px);
  pointer-events: none;
}
.dd_style1{
  width: 100%;
  border-radius: 5px;
}

@media (max-width:576px){
    .dd_container{ width: calc(100% + 20px); }
}
@media (max-width:768px) and (min-width:576px){
    .dd_container{ width: calc(100% + 20px); }
}
@media screen and (orientation:landscape) and (max-width:992px) {
    .dd_container{ width: calc(100% + 20px); }
}`, "",{"version":3,"sources":["webpack://./src/Components/BasicComponents/Select.css"],"names":[],"mappings":"AAAA;EACE,YAAY;IACV,cAAc;IACd,eAAe;IACf,kBAAkB;AACtB;;AAEA;EACE,SAAS;EACT,YAAY;AACd;AACA;EACE,WAAW;EACX,uBAAuB;EACvB,wBAAwB;EACxB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,eAAe;AACjB;AACA;IACI,aAAa;AACjB;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,oBAAoB;EACpB,oBAAoB;AACtB;AACA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;IACI,eAAe,wBAAwB,EAAE;AAC7C;AACA;IACI,eAAe,wBAAwB,EAAE;AAC7C;AACA;IACI,eAAe,wBAAwB,EAAE;AAC7C","sourcesContent":[".dd_container{\r\n  width: 320px;\r\n    display: block;\r\n    font-size: 14px;\r\n    position: relative;\r\n}\r\n\r\n.dd_information_text{\r\n  margin: 0;\r\n  padding: 3px;\r\n}\r\n.dd_select{\r\n  width: 100%;\r\n  background: transparent;\r\n  -webkit-appearance: none;\r\n  padding-right: 30px;\r\n  border: none;\r\n  padding: 15px;\r\n  border: 1px solid #e9ddd0;\r\n  background-color: white;\r\n  height: 36px;\r\n  padding: 0 10px;\r\n  font-size: 18px;\r\n}\r\n.dd_select::-ms-expand {\r\n    display: none;\r\n}\r\n.dd_icon{\r\n  position: absolute;\r\n  right: 6px;\r\n  top: calc(50% - 2px);\r\n  pointer-events: none;\r\n}\r\n.dd_style1{\r\n  width: 100%;\r\n  border-radius: 5px;\r\n}\r\n\r\n@media (max-width:576px){\r\n    .dd_container{ width: calc(100% + 20px); }\r\n}\r\n@media (max-width:768px) and (min-width:576px){\r\n    .dd_container{ width: calc(100% + 20px); }\r\n}\r\n@media screen and (orientation:landscape) and (max-width:992px) {\r\n    .dd_container{ width: calc(100% + 20px); }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
