import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './Slider.css';

var interval;
function mapStateToProps({ app, user }) {
    return {
        app: app,
        user: user
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

class _Slider extends Component {
    static propTypes = {
        slides: PropTypes.array,
        app: PropTypes.object,
        user: PropTypes.object,
        location: PropTypes.object,
        history: PropTypes.object
    };
    constructor(props) {
        super(props);
        this.state = {
            slide: 0
        };
    }
    componentDidMount(){
        interval = setInterval(() => {
            this.setState({ slide: (this.state.slide + 1)%this.props.slides.length });
        }, 2000);
    }
    componentWillUnmount(){
        clearInterval(interval);
    }
    moveSlide(id){
        this.setState({ slide: id });
    }
    render() {
        return (
            <div className="slider_main">
                <div className="slider_container">
                    <img className='slider_image' alt="" src={this.props.slides[this.state.slide]}/>
                    {/* <div className="slider_dots">
                        {
                            this.props.slides.length > 0 && this.props.slides.map((x,i)=>{
                                return(
                                    <div key={i} className={className("slider_dot", this.state.slide === i && "slider_select")} onClick={()=>this.moveSlide(i)}></div>
                                )
                            })
                        }
                    </div> */}
                </div>
            </div>
        );
    }
}

export const Slider = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_Slider)
);