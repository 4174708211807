import React from 'react';
import PropTypes from 'prop-types';
import './Icon.css';
import classNames from 'classnames';
import { Icons } from './Icons';

const sizeToClassName = {
  xsmall: 'XSmall',
  small: 'Small',
  medium: 'Medium',
  normal: 'Normal',
  large: 'Large',
  xlarge: 'XLarge',
  xxlarge: 'XXLarge'
};
export class Icon extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    rotate: PropTypes.bool,
    size: PropTypes.string,
    children: PropTypes.string.isRequired
  };
  render() {
    const icon = Icons[this.props.children];
    const sizeClass = sizeToClassName[this.props.size] || "Medium";
    if (!icon) {
      return '';
    }
    else if(icon.type === 'svg') {
      return icon;
    } else {
      return (
        <svg
          viewBox='0 0 24 24'
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
          className={classNames("ico_icon", sizeClass)}
        >
          <title>{this.props.children}</title>
          <g className={this.props.rotate ? "Rotate" : ''}>{icon}</g>
        </svg>
      );
    }
  }
}
