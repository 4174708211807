export const BaseValidators = {
    mandatory: value => {
      if (value === undefined || value === null || value.length === 0) {
        return {
          valid: false,
          errors: [('mandatory')]
        };
      }
      return { valid: true };
    },
    accept: value => {
      if (value === undefined || value === null || value.length === 0 || value === false) {
        return {
          valid: false,
          errors: [('accept')]
        };
      }
      return { valid: true };
    },
    email: value => {
      const emailRegexpPattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (
        !emailRegexpPattern.test(value) &&
        value !== undefined &&
        value !== null &&
        value !== ''
      ) {
        return {
          valid: false,
          errors: [('emailInvalid')]
        };
      }
      return { valid: true };
    },
    alphabetical: value => {
      const nameRegexpPattern = /^[a-zA-Z\s]+$/;
      if (
        !nameRegexpPattern.test(value) &&
        value !== undefined &&
        value !== null &&
        value !== ''
      ) {
        return {
          valid: false,
          errors: [('valueInvalid')]
        };
      }
      return { valid: true };
    },
    confirmcode: value => {
      const nameRegexpPattern = /^[0-9]{6}$/;
      if(value) {
        if (
          !nameRegexpPattern.test(value)
        ) {
          return {
            valid: false,
            errors: [('valueInvalid')]
          };
        }
      }    
      return { valid: true };
    },
    numeric: value => {
      const nameRegexpPattern = /^([0-9]+)$/;
      if(value) {
        if (
          !nameRegexpPattern.test(value)
        ) {
          return {
            valid: false,
            errors: [('valueInvalid')]
          };
        }
      }    
      return { valid: true };
    },
    telephone: value => {
      const phoneNumberRegexpPattern = /^(\+\d{1,3}[- ]?)?\d{10}$/;
      if (
        !phoneNumberRegexpPattern.test(value) &&
        value !== undefined &&
        value !== null &&
        value !== ''
      ) {
        return {
          valid: false,
          errors: [('phoneNumberInvalid')]
        };
      }
      return { valid: true };
    },
    zipCode: value => {
      const zipCodeRegexpPattern = /^(?=.*\d)[\d ]+$/;
      if (
        !zipCodeRegexpPattern.test(value) &&
        value !== undefined &&
        value !== null &&
        value !== ''
      ) {
        return {
          valid: false,
          errors: [('zipCodeInvalid')]
        };
      }
      return { valid: true };
    },
    passwordStrength: value => {
      const zipCodeRegexpPattern = /^((?=.*[A-Z])|(?=.*[!@#$&*])|(?=.*[0-9])|(?=.*[a-z].*[a-z])).{8,}$/;
      if (
        !zipCodeRegexpPattern.test(value) &&
        value !== undefined &&
        value !== null &&
        value !== ''
      ) {
        return {
          valid: false,
          errors: [('notAStrongPassword')]
        };
      }
      return { valid: true };
    },
    passwordMatch: (value1, value2) =>{
      if (value1 !== value2){
        return {
          valid: false,
          errors: [('passwordMismatch')]
        };
      }
      return { valid: true };
    },
    personalnumber: value => {
      const personalnumberPattern = /^(\d{8}-\d{4})$/;
      if (
        !personalnumberPattern.test(value) &&
        value !== undefined &&
        value !== null &&
        value !== ''
      ) {
        return {
          valid: false,
          errors: [('personalNumberInvalid')]
        };
      }
      return { valid: true };
    },
  };