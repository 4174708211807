import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './FinanceReportPage.css';
import { OpenPopup } from '../../Actions';
import i18next from 'i18next';

function mapStateToProps({ app, user }) {
    return {
        app: app,
        user: user
    };
}

const mapDispatchToProps = dispatch => {
    return {
        openPopup: data => {
          dispatch(OpenPopup(data));
        },
    };
};
const year = ["2022"];
class _FinanceReportPage extends Component {
    static propTypes = {
        user: PropTypes.object,
        location: PropTypes.object,
        history: PropTypes.object
    };
    constructor(props) { 
        super(props);
        this.state = {
            selected: 0,
        }
    }
    render() {
        return (
            <div className="contact_container">
                <div className='contact_page_header'>
                    <h3>{i18next.t('fyreport.pagetitle') + " " + this.props.match.params.year}</h3>
                </div>
                <div className='finance'>
                    {
                        year.includes(this.props.match.params.year) ?
                        <React.Fragment>
                            <div className='financeReport'>
                                <div className='financeReportHeader'>
                                    <div className='orgLogo'>
                                        <img src='https://almhulttamilsangam.se/static/media/ATS-Logo.ba66501bb91d3034cf5f.png' alt='ÄTS-Logo' style={{width:"100px"}}></img>
                                    </div>
                                    <div className='orgTitle'>
                                        <div>
                                            <div className='orgName'>Älmhult Tamil Association - Sweden</div>
                                            <div className='orgNumber'>Organization Number: 802529-3591</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='divider'>
                                    <hr/>
                                </div>
                                <div className='financeReportBody'>
                                    <div className='reportdate'>
                                        Date: 15-April-2023
                                    </div>
                                    <div className='reportdetail'>
                                    Dear ATS members, <br/> <br/>
                                    We hope this email finds you well. <br/>
                                    As discussed during our AGM meeting held on 15-Jan-2023, we wanted to provide a summary
                                    of the association economy and conclude the finance report for FY22. <br/> <br/>
                                    Financial results: <br/> <br/>
                                    <b><u>58591 SEK income</u></b> <br/> <br/>
                                    1194 FY21 surplus <br/>
                                    10700 Membership fees <br/>
                                    2000 Kommun startup stipend (one time) <br/>
                                    31085 Events entry fees <br/>
                                    13612 Weekly activities <br/> <br/>
                                    <b><u>67817 SEK expense</u></b> <br/> <br/>
                                    3947 Banking & operational costs <br/>
                                    50650 Events <br/>
                                    12220 Weekly activities <br/>
                                    1000 Previous year’s settlement <br/> <br/>
                                    The association ended the year with a <b>negative result of 9225 SEK.</b><br/> <br/>
                                    Board comments:<br/> <br/>
                                    The deficit can be attributed to several factors, being the first year as a fully functional
                                    association there were outreach efforts to include new members, including the expansion of our
                                    services in organizing cultural,sports & entertainment events, also to provide the services at
                                    possible low cost and cases where higher expenses incurred than planned in organizing the
                                    events and activities.<br/>
                                    With that we welcome our new board for FY23, and we are working in co-operation to mitigate
                                    the situation.<br/> <br/>
                                    We would like to express our gratitude to all our members for their continued support and
                                    participation in the association. We value your feedback and input, and encourage you to share
                                    any suggestions or questions you may have. Please feel free to reply to this email, and we will
                                    get back to you as soon as possible.<br/> <br/>
                                    Thank you for your time and attention.<br/> <br/>
                                    Best regards,<br/>
                                    Board Members FY22
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>:
                        <React.Fragment>
                            <div className='reportNotAvailable'>
                                The report you are looking for is not available. Please contact the board members.
                                {/* <div style="justify-content: center; align-items: center; display: flex;">
                                    <div style="width: 1024px;">
                                        <div class="financeReportHeader">
                                            <center>
                                                <div style="justify-content: center; display: flex; align-items: center;">
                                                <img src="https://almhulttamilsangam.se/static/media/ATS-Logo.ba66501bb91d3034cf5f.png" alt="ÄTS-Logo" style="width: 100px;"/>
                                                </div>
                                                <div style="height: 100px; display: flex;  justify-content: center; align-items: center; text-align: center;">
                                                <div>
                                                <div style="display: flex; justify-content: center; font-size: 26px; font-weight: bold; text-align: center;">Älmhult Tamil Association - Sweden</div>
                                                <div style="display: flex;justify-content: center;font-size: 16px;font-weight: bold;text-align: center;">Organization Number: 802529-3591</div>
                                                </div>
                                                </div>
                                            </center>
                                        </div>
                                        <div class="divider"><hr/></div>
                                        <div class="financeReportBody">
                                            <div style="justify-content: right; display: flex;">Date: 15-April-2023</div>
                                            <div>Dear ATS members, <br/> <br/>We hope this email finds you well. <br/>As discussed during our AGM meeting held on 15-Jan-2023, we wanted to provide a summary of the association economy and conclude the finance report for FY22. <br/> <br/>Financial results: <br/> <br/><b><u>58591 SEK income</u></b> <br/> <br/>1194 FY21 surplus <br/>10700 Membership fees <br/>2000 Kommun startup stipend (one time) <br/>31085 Events entry fees <br/>13612 Weekly activities <br/> <br/><b><u>67817 SEK expense</u></b> <br/> <br/>3947 Banking &amp; operational costs <br/>50650 Events <br/>12220 Weekly activities <br/>1000 Previous year’s settlement <br/> <br/>The association ended the year with a <b>negative result of 9225 SEK.</b><br/> <br/>Board comments:<br/> <br/>The deficit can be attributed to several factors, being the first year as a fully functional association there were outreach efforts to include new members, including the expansion of our services in organizing cultural,sports &amp; entertainment events, also to provide the services at possible low cost and cases where higher expenses incurred than planned in organizing the events and activities.<br/>With that we welcome our new board for FY23, and we are working in co-operation to mitigate the situation.<br/> <br/>We would like to express our gratitude to all our members for their continued support and participation in the association. We value your feedback and input, and encourage you to share any suggestions or questions you may have. Please feel free to reply to this email, and we will get back to you as soon as possible.<br/> <br/>Thank you for your time and attention.<br/> <br/>Best regards,<br/>Board Members FY22</div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}

export const FinanceReportPage = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_FinanceReportPage)
);