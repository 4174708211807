import React from 'react';

export class Progressbar extends React.Component {
  render() {
    return (
      <svg style={{margin:'auto', background: '#fff', display: 'block'}} width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <g transform="translate(20 20)">
        <rect x="-15" y="-15" width="30" height="30" fill="#e15b64" transform="scale(0.272053 0.272053)">
          <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" calcMode="spline" dur="1s" values="1;1;0.2;1;1" keyTimes="0;0.2;0.5;0.8;1" keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5" begin="-0.4s"></animateTransform>
        </rect></g>
      <g transform="translate(50 20)">
        <rect x="-15" y="-15" width="30" height="30" fill="#f47e60" transform="scale(0.516923 0.516923)">
          <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" calcMode="spline" dur="1s" values="1;1;0.2;1;1" keyTimes="0;0.2;0.5;0.8;1" keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5" begin="-0.3s"></animateTransform>
        </rect></g>
      <g transform="translate(80 20)">
        <rect x="-15" y="-15" width="30" height="30" fill="#f8b26a" transform="scale(0.792738 0.792738)">
          <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" calcMode="spline" dur="1s" values="1;1;0.2;1;1" keyTimes="0;0.2;0.5;0.8;1" keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5" begin="-0.2s"></animateTransform>
        </rect></g>
      <g transform="translate(20 50)">
        <rect x="-15" y="-15" width="30" height="30" fill="#f47e60" transform="scale(0.516923 0.516923)">
          <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" calcMode="spline" dur="1s" values="1;1;0.2;1;1" keyTimes="0;0.2;0.5;0.8;1" keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5" begin="-0.3s"></animateTransform>
        </rect></g>
      <g transform="translate(50 50)">
        <rect x="-15" y="-15" width="30" height="30" fill="#f8b26a" transform="scale(0.792738 0.792738)">
          <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" calcMode="spline" dur="1s" values="1;1;0.2;1;1" keyTimes="0;0.2;0.5;0.8;1" keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5" begin="-0.2s"></animateTransform>
        </rect></g>
      <g transform="translate(80 50)">
        <rect x="-15" y="-15" width="30" height="30" fill="#abbd81" transform="scale(1 1)">
          <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" calcMode="spline" dur="1s" values="1;1;0.2;1;1" keyTimes="0;0.2;0.5;0.8;1" keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5" begin="-0.1s"></animateTransform>
        </rect></g>
      <g transform="translate(20 80)">
        <rect x="-15" y="-15" width="30" height="30" fill="#f8b26a" transform="scale(0.792738 0.792738)">
          <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" calcMode="spline" dur="1s" values="1;1;0.2;1;1" keyTimes="0;0.2;0.5;0.8;1" keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5" begin="-0.2s"></animateTransform>
        </rect></g>
      <g transform="translate(50 80)">
        <rect x="-15" y="-15" width="30" height="30" fill="#abbd81" transform="scale(1 1)">
          <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" calcMode="spline" dur="1s" values="1;1;0.2;1;1" keyTimes="0;0.2;0.5;0.8;1" keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5" begin="-0.1s"></animateTransform>
        </rect></g>
      <g transform="translate(80 80)">
        <rect x="-15" y="-15" width="30" height="30" fill="#849b87" transform="scale(1 1)">
          <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" calcMode="spline" dur="1s" values="1;1;0.2;1;1" keyTimes="0;0.2;0.5;0.8;1" keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5" begin="0s"></animateTransform>
        </rect></g>
      
      </svg>
    );
  }
}
