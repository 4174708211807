import React from 'react';
import PropTypes from 'prop-types';
import './MenuHolder.css';

export class MenuHolder extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    text: PropTypes.string,
    closePopup: PropTypes.func,
    children: PropTypes.any,
    outsideClickClose: PropTypes.bool,
    noClose: PropTypes.bool
  };
  menuItemClick = () =>{
    this.props.closePopup();
  }
  close = () => {
    this.props.outsideClickClose && this.props.closePopup();
  }
  render() {
    return (
      <React.Fragment>
        <div className="mh_popup" style={{ display: (this.props.open ? 'inline' : 'none'), top: this.props.top, right: this.props.right }}>
          <div className="mh_popup_body">
            <div>
              {this.props.children}
            </div>
          </div>
        </div>
        <div className="mh_background" style={{ display: (this.props.open ? 'inline' : 'none') }} onClick={()=>this.close()}>

        </div>
      </React.Fragment>
    );
  }
}
