import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './EventDetailsPage.css';
import { AddCommentsToEvent, AddNotesToEvent, DeleteComment, EventUpdate, LoadEventDetails, OpenPopup, resetPropMessages, setPropMessages } from '../../Actions';
import { Button, Icon, Popup, TextArea } from '../../Components';
import i18next from 'i18next';
import moment from 'moment';
import { validate } from '../../Validation';
import profilePic from "../../Images/user.png";

function mapStateToProps({ app, user, event }) {
    return { app, user, event };
}

const mapDispatchToProps = dispatch => {
    return {
        setPropMessages: (key, error) => {
            dispatch(setPropMessages(key, error));
        },
        resetPropMessages: key => {
            dispatch(resetPropMessages(key));
        },
        openPopup: data => {
            dispatch(OpenPopup(data));
        },
        load: (id) => {
            dispatch(LoadEventDetails(id))
        },
        eventUpdate: (data) => {
            dispatch(EventUpdate(data))
        },
        addNotes: (id, notes) => {
            dispatch(AddNotesToEvent(id, notes))
        },
        addComment: (id, comment) => {
            dispatch(AddCommentsToEvent(id, comment))
        },
        deleteComment: (id, commentId) => {
            dispatch(DeleteComment(id, commentId))
        },
    };
};

class _EventDetailsPage extends Component {
    static propTypes = {
        user: PropTypes.object,
        location: PropTypes.object,
        history: PropTypes.object
    };
    constructor(props) {
        super(props);
        this.state = {
            deletePopup: false,
        }
    }
    componentDidMount() {
        this.props.load(this.props.match.params.id);
    }
    valid(data, validator) {
        var valid = true;
        Object.keys(validator).forEach(key => {
            const validation = validate(data, key, validator[key]);
            if (!validation.valid) {
                this.props.setPropMessages(key, validation.errors);
                valid = false;
            } else {
                this.props.resetPropMessages(key);
            }
        });
        return valid;
    }
    addNote() {
        var obj = { message: this.props.event.notes }
        if (this.valid(obj, { message: ['mandatory'] })) {
            this.props.addNotes(this.props.match.params.id, obj);
        }
    }
    addComment() {
        var obj = { message: this.props.event.comment }
        if (this.valid(obj, { message: ['mandatory'] })) {
            this.props.addComment(this.props.match.params.id, obj);
        }
    }
    getEventFromList() {
        var event = this.props.event.list.filter(x => (x.eventId + "") === this.props.match.params.id);
        if (event) {
            return event[0];
        } else {
            return null;
        }
    }
    deleteComment(){
        this.setState({ deletePopup: false, commentId: null });
        this.props.deleteComment(this.props.match.params.id, this.state.commentId);
    }
    render() {
        var eventNotLogin = this.getEventFromList();
        var user = this.props.user.user?.familyUsers.filter(x => x.id === this.props.user.user.id)[0];
        var login = user !== null && user !== undefined;
        const event = this.props.event.eventDetails?.event;
        const notes = event ? this.props.event.eventDetails?.eventDetail.filter(x => x.type === "Notes") : [];
        const comment = event ? this.props.event.eventDetails?.eventDetail.filter(x => x.type === "Comment") : [];
        return (
            <div className="contact_container">
                <div className='ed_page_header'>
                    {
                        login ?
                            <React.Fragment>
                                {

                                    event ?
                                        <React.Fragment>
                                            <div>
                                                <h3>{this.props.app.language === 'ta' ? event.nameTamil : event.name} {event.year}</h3>
                                                {
                                                    this.props.user.role === "Admin" &&
                                                    <div className='event_details_admin_buttons'>
                                                        <Button onClick={() => { this.props.eventUpdate({ addNotes: true }) }}>Add Notes</Button>
                                                    </div>
                                                }
                                            </div>
                                            {
                                                this.props.event.addNotes &&
                                                <div className='ed_form_content'>
                                                    <TextArea name="message" type="text" className="full_len_textarea" value={this.props.event.notes} title={i18next.t('events.notes')} placeholder={i18next.t('events.notes').replace(" *", "")} onChange={(e) => this.props.eventUpdate({ notes: e.target.value })}></TextArea>
                                                    <div className='ed_action_buttons'>
                                                        <Button onClick={() => this.addNote()}>{i18next.t('register.save')}</Button>
                                                        <Button onClick={() => this.props.eventUpdate({ addNotes: false })}>{i18next.t('register.cancel')}</Button>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                notes.length > 0 &&
                                                <div className='ed_notes'>
                                                    <h3>Notes</h3>
                                                    {
                                                        notes.map((x, i) => {
                                                            return (
                                                                <div key={i} className='event_note'>
                                                                    <div className='event_notes_message'>
                                                                        {
                                                                            x.message.split(/\r?\n|\r|\n/g).map((y, j) => {
                                                                                return (
                                                                                    <React.Fragment key={j}>
                                                                                        <div >{y}</div>
                                                                                        <br />
                                                                                    </React.Fragment>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    <div className='event_note_time'>
                                                                        Added on: {moment(x.createdTime).format("LLL")}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                            {
                                                notes.length > 0 &&
                                                <div className='ed_divider'>
                                                    <hr></hr>
                                                </div>
                                            }
                                            {
                                                comment.length > 0 &&
                                                <div className='ed_notes'>
                                                    <h3 className='ed_comment_line'>Comments:</h3>
                                                    {
                                                        comment.map((x, i) => {
                                                            return (
                                                                <div key={i} className='event_comment'>
                                                                    <div className='event_comment_message'>
                                                                        {
                                                                            x.message.split(/\r?\n|\r|\n/g).map((y, j) => {
                                                                                return (
                                                                                    <React.Fragment key={j}>
                                                                                        <div >{y}</div>
                                                                                        <br />
                                                                                    </React.Fragment>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    <div className='event_note_time'>
                                                                        Commented by {(user.firstName + " " + user.lastName) === x.user ? "you" : x.user} on {moment(x.createdTime).format("LLL")}
                                                                    </div>
                                                                    {
                                                                        (user.firstName + " " + user.lastName) === x.user &&
                                                                        <div className='ed_delete_my_comment' onClick={() => { this.setState({ deletePopup: true, commentId: x.id }) }}>
                                                                            <Icon>delete</Icon>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                            {
                                                user &&
                                                <div className='ed_comment_add_container'>
                                                    <div className='ed_comment_add_userpic'>
                                                        <img src={user?.pic || profilePic} alt={user.displayName} className="user_profile"></img>
                                                    </div>
                                                    <div className='ed_comment_add_text'>
                                                        <TextArea name="comment" type="text" className="ed_comment_textbox_style" value={this.props.event.comment} title={""} placeholder={i18next.t('events.commentbox')} onChange={(e) => this.props.eventUpdate({ comment: e.target.value })}></TextArea>
                                                    </div>
                                                    <div className='ed_comment_add_send' onClick={() => this.addComment()}>
                                                        <Icon>send</Icon>
                                                    </div>
                                                </div>
                                            }
                                        </React.Fragment> :
                                        <h3>{this.props.app.language === 'ta' ? "நிகழ்ச்சி விவரங்கள் எதுவும் இல்லை." : "No Event Details found."}</h3>
                                }
                            </React.Fragment> :
                            <React.Fragment>
                                {
                                    eventNotLogin ?
                                        <>
                                            <h3>{this.props.app.language === 'ta' ? eventNotLogin.nameTamil : eventNotLogin.name} {eventNotLogin.year}</h3>
                                            <div>
                                                To view the event details or comment your feedback you need to login first.
                                            </div>
                                            <div>
                                                <Button onClick={() => this.props.history.push("/memberlogin")}>{i18next.t('menu.memberlogin')}</Button>
                                            </div>
                                        </> :
                                        <h3>{this.props.app.language === 'ta' ? "நிகழ்ச்சி விவரங்கள் எதுவும் இல்லை." : "No Event Details found."}</h3>
                                }
                            </React.Fragment>
                    }
                </div>
                <Popup open={this.state.deletePopup} noClose>
                    <div className='ed_delete_confirm_popup'>
                        Are you sure want to delete your comment?
                    </div>
                    <div className='ed_action_buttons'>
                        <Button onClick={() => { this.deleteComment() }}>{i18next.t('common.yes')}</Button>
                        <Button onClick={() => { this.setState({ deletePopup: false, commentId: null }) }}>{i18next.t('common.no')}</Button>
                    </div>
                </Popup>
            </div>
        );
    }
}

export const EventDetailsPage = withRouter( 
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_EventDetailsPage)
);