import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './TextArea.css'
import i18next from 'i18next';

function mapStateToProps({ field }) {
  return {
    validation: field
  };
}

const mapDispatchToProps = dispatch => {
  return {
  };
};

class _TextArea extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        type: PropTypes.string,
        class: PropTypes.string,
        title: PropTypes.string,
        placeholder: PropTypes.string,
        optional: PropTypes.bool,
        hasWarning: PropTypes.bool,
        isConfirmed: PropTypes.bool,
        disabled: PropTypes.bool,
        isInvalid: PropTypes.bool,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onSubmit: PropTypes.func,
        value: PropTypes.string,
        className: PropTypes.string,
        regExPattern: PropTypes.string,
        style: PropTypes.object,
        validation: PropTypes.object
    };

    render() {
      const local = i18next.t('validationMsg', {returnObjects: true});
        const className = classNames(
          "txt_container", {"Disabled": this.props.disabled,
            "txt_confirmation": this.props.isConfirmed,
            "txt_warning": this.props.hasWarning,
            "txt_error": this.props.isInvalid},
          this.props.className
        );
        const placeholder = (this.props.placeholder ? this.props.placeholder : "") + (this.props.optional ? ' (Optional)' : '');
        const disabled = this.props.disabled && true ? 'disabled' : false;
    
        return (
            <React.Fragment>
              <div className={className} style={this.props.style}>
                <p className={classNames("txt_information_text")}>{this.props.title}</p>
                <textarea
                  className={classNames("txt_area")}
                  name={this.props.name}
                  type={this.props.type}
                  onBlur={event => {
                    if (this.props.onBlur) this.props.onBlur(event);
                  }}
                  onChange={event => {
                    if (this.props.onChange) this.props.onChange(event);
                  }}
                  value={this.props.value || ''}
                  placeholder={placeholder ? placeholder : ""}
                  disabled={disabled}
                  pattern={this.props.regExPattern}
                  onKeyUp={(e)=> { 
                    if (e.keyCode === 13){
                      if (this.props.onSubmit) this.props.onSubmit(e);
                    } 
                  }}
                />
                {
                  this.props.validation[this.props.name] && this.props.validation[this.props.name].map((v, i) => {
                    return(<div key={i}  className="error_message">{local[v]}</div>)
                  })
                }
              </div>
            </React.Fragment>
        );
    }
}

export const TextArea = withRouter(
  connect(
      mapStateToProps,
      mapDispatchToProps
  )(_TextArea)
);