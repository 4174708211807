import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './AboutUsPage.css';
import { OpenPopup } from '../../Actions';

function mapStateToProps({ app, user }) {
    return {
        app: app,
        user: user
    };
}

const mapDispatchToProps = dispatch => {
    return {
        openPopup: data => {
          dispatch(OpenPopup(data));
        },
    };
};

class _AboutUsPage extends Component {
    static propTypes = {
        user: PropTypes.object,
        location: PropTypes.object,
        history: PropTypes.object
    };
    GoToGame(name){
        this.props.history.push('/' + name);
    }
    componentDidMount(){
        this.redirect();
    }
    componentDidUpdate(prevProps){
        this.redirect();
    }
    redirect(){
        // if (this.props.location.pathname === "/"){
        //     this.GoToGame('single');
        // }
    }
    render() {
        return (
            <div className="home_selection">
                <div style={{display:'flex', flexDirection: 'column'}}>
                    <div style={{display:'flex', }}>
                        <div style={{display:'block' }}>
                            <h3>About Us Page</h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export const AboutUsPage = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_AboutUsPage)
);