import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './ContactUsPage.css';
import { OpenPopup, resetAllPropMessages, setPropMessages, SubmitContactUs, UpdateContactForm } from '../../Actions';
import { Button, TextBox, TextArea } from '../../Components';
import i18next from 'i18next';
import { validate } from '../../Validation';

function mapStateToProps({ app, user }) {
    return { app, user };
}

const mapDispatchToProps = dispatch => {
    return {
        openPopup: data => {
          dispatch(OpenPopup(data));
        },
        updatecontact: data => {
          dispatch(UpdateContactForm(data));
        },
        register: data => {
            dispatch(SubmitContactUs(data))
        },
        setPropMessageslocal: (data, msg)=>{
            dispatch(setPropMessages(data, msg))
        },
        resetAllPropMessageslocal: ()=>{
            dispatch(resetAllPropMessages())
        },
    };
};

class _ContactUsPage extends Component {
    static propTypes = {
        user: PropTypes.object,
        location: PropTypes.object,
        history: PropTypes.object
    };
    updateValueOnChange(e){
        this.props.updatecontact({name: e.target.name, value: e.target.value});
    }
    register(){
        if (this.validation(this.props.user.contact, { fullName: ['mandatory','alphabetical'], message: ['mandatory'], subject: ['mandatory'], email: ['mandatory','email'] })){
            this.props.register(this.props.user.contact);
        }
    }
    validation(data, validator){
        this.props.resetAllPropMessageslocal();
        var valid = true;
        Object.keys(validator).forEach(key => {
            const validation = validate(data, key, validator[key]);
            if (!validation.valid) {
                this.props.setPropMessageslocal(key, validation.errors);
                valid = false;
            }
        });
        return valid;
    }
    render() {
        return (
            <React.Fragment>
                {
                    this.props.user.contact.submit === "success" &&
                    <div className="contact_success_message">
                        {i18next.t('contact.successMsg')}
                    </div>
                }
                <div className="contact_container">
                    <div className='contact_page_header'>
                        <h3>{i18next.t('contact.pagetitle')}</h3>
                    </div>
                    <div className='contact_form_container'>
                        <div className='contact_form_row'>
                            <div className='contact_form_column'>
                                <TextBox name="fullName" type="text" className="style1" value={this.props.user.contact.fullName} title={i18next.t('contact.fullname')} placeholder={i18next.t('contact.fullname').replace(" *","")} onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                            </div>
                            <div className='contact_form_column'>
                                <TextBox name="email" type="text" className="style1" value={this.props.user.contact.email} title={i18next.t('contact.email')} placeholder={i18next.t('contact.email').replace(" *","")} onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                            </div>
                        </div>
                        <div className='contact_form_row'>
                            <div className='contact_form_column'>
                                <TextBox name="subject" type="text" className="style1" value={this.props.user.contact.subject} title={i18next.t('contact.subject')} placeholder={i18next.t('contact.subject').replace(" *","")} onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                            </div>
                        </div>
                        <div className='contact_form_row'>
                            <div className='contact_form_column'>
                                <TextArea name="message" type="text" className="style1" value={this.props.user.contact.message} title={i18next.t('contact.message')} placeholder={i18next.t('contact.message').replace(" *","")} onChange={(e) => this.updateValueOnChange(e)}></TextArea>
                            </div>
                        </div>
                        <div className='contact_form_row_single_center'>
                            <div className='contact_form_column_single_center'>
                                <Button onClick={() => this.register()}>{i18next.t('contact.submit')}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export const ContactUsPage = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_ContactUsPage)
);