// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.diwali24_page_container{
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.diwali24_page_content{
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.diwali24_image_container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.diwali24_image{
  width: 80%;
}
.diwali24_text_content{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}
.diwali24_p{
  font-size: 28px;
  font-weight: 400;
  font-style: normal;
}
@media (max-width:576px){
  .diwali24_page_content{ max-width: 90%; }
  
}
@media (max-width:768px) and (min-width:576px){
  .diwali24_page_content{ max-width: 536px; }
}
@media (max-width:992px) and (min-width:768px){
  .diwali24_page_content{ max-width: 728px; }
}
@media (max-width:1200px) and (min-width:992px){
  .diwali24_page_content{ max-width: 952px; }
}
@media (min-width:1200px){
  .diwali24_page_content{ max-width: 1200px; }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Diwali2024Page/Diwali2024Page.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;AACxB;AACA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,UAAU;AACZ;AACA;EACE,aAAa;EACb,2BAA2B;EAC3B,uBAAuB;EACvB,sBAAsB;EACtB,WAAW;AACb;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,wBAAwB,cAAc,EAAE;;AAE1C;AACA;EACE,wBAAwB,gBAAgB,EAAE;AAC5C;AACA;EACE,wBAAwB,gBAAgB,EAAE;AAC5C;AACA;EACE,wBAAwB,gBAAgB,EAAE;AAC5C;AACA;EACE,wBAAwB,iBAAiB,EAAE;AAC7C","sourcesContent":[".diwali24_page_container{\r\n  display: flex;\r\n  flex-direction: column;\r\n  width: 100%;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n.diwali24_page_content{\r\n  overflow: hidden;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  flex-direction: column;\r\n}\r\n.diwali24_image_container{\r\n  width: 100%;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n.diwali24_image{\r\n  width: 80%;\r\n}\r\n.diwali24_text_content{\r\n  display: flex;\r\n  justify-content: flex-start;\r\n  align-items: flex-start;\r\n  flex-direction: column;\r\n  width: 100%;\r\n}\r\n.diwali24_p{\r\n  font-size: 28px;\r\n  font-weight: 400;\r\n  font-style: normal;\r\n}\r\n@media (max-width:576px){\r\n  .diwali24_page_content{ max-width: 90%; }\r\n  \r\n}\r\n@media (max-width:768px) and (min-width:576px){\r\n  .diwali24_page_content{ max-width: 536px; }\r\n}\r\n@media (max-width:992px) and (min-width:768px){\r\n  .diwali24_page_content{ max-width: 728px; }\r\n}\r\n@media (max-width:1200px) and (min-width:992px){\r\n  .diwali24_page_content{ max-width: 952px; }\r\n}\r\n@media (min-width:1200px){\r\n  .diwali24_page_content{ max-width: 1200px; }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
