import React from 'react';
import PropTypes from 'prop-types';
import './Tabs.css';

export class Tabs extends React.Component {
  constructor(props) { 
      super(props);
      this.state = {
        selectedTab: 0
      }
  }
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    class: PropTypes.string, 
    style: PropTypes.object
  };
  createArrayOfSize(size){
      var array = [];
      for (var i = 0; i < size; i++){
          array.push(i);
      }
      return array;
  }
  render() {
    var tabCount = this.props.children?.length || 0;
    if (tabCount < 2){
      return(
        <div>{"Tabs component should have atleast two tab inside."}</div>
      )
    }
    return (
        <React.Fragment>
          <div className='tabs_container'>
            <div className='tabs_selecter'>
              {
                this.createArrayOfSize(tabCount).map((x, i)=>{
                  return(
                    <div key={x} className={this.state.selectedTab === i ? "tab_selected": "tab"} onClick={()=>{this.setState({selectedTab:i})}}>
                      {this.props.children[i].props.name}
                    </div>
                  )
                })
              }
            </div>
            <div className='current_tab'>
              {
                this.props.children[this.state.selectedTab]
              }
            </div>
          </div>
        </React.Fragment>
    );
  }
}

export class Tab extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    class: PropTypes.string, 
    style: PropTypes.object
  };
  render() {
    return (
        <React.Fragment>
        <div>
          {
            this.props.children
          }
        </div>
        </React.Fragment>
    );
  }
}
