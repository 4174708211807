import { FieldsValidationsActions as Actions } from '../Actions';

const initialState = {};

export const fieldsValidationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_PROP_MESSAGES: {
      const newState = {
        ...state,
        [action.payload.name]: action.payload.messages
      };
      return newState;
    }
    case Actions.RESET_PROP_MESSAGES: {
      const newState = {
        ...state,
        [action.payload.name]: null
      };
      return newState;
    }
    case Actions.RESET_ALL_PROP_MESSAGES: {
      return {};
    }
    default:
      return state;
  }
};
