import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './DeveloperPage.css';
import { DeveloperMode, OpenPopup } from '../../Actions';
import { Button } from '../../Components';
import i18next from 'i18next';

function mapStateToProps({ app, user }) {
    return {
        app: app,
        user: user
    };
}

const mapDispatchToProps = dispatch => {
    return {
        changeMode: mode =>{
            dispatch(DeveloperMode(mode));
        },
        openPopup: data => {
          dispatch(OpenPopup(data));
        },
    };
};

class _DeveloperPage extends Component {
    static propTypes = {
        user: PropTypes.object,
        location: PropTypes.object,
        history: PropTypes.object
    };
    constructor(props) { 
        super(props);
        this.state = {
            refresh: "",
        }
    }
    enableDisable(){
        if (localStorage.getItem("developerMode")==="false" || localStorage.getItem("developerMode")!=="true"){
            this.props.changeMode("enable");
            localStorage.setItem("developerMode", "true");
        } else {
            this.props.changeMode("diable");
            localStorage.setItem("developerMode", "false");
        }
    }
    render() {
        return (
            <div className="home_selection">
                <div style={{display:'flex', flexDirection: 'column'}}>
                    <div style={{display:'flex', }}>
                        <div style={{display:'block' }}>
                            <h3>{i18next.t('developer.title')}</h3>
                        </div>
                    </div>
                </div>
                <div className='register_form_row_single_center'>
                    <div className='register_form_column_single_center'>
                        <Button onClick={() => this.enableDisable()}>{i18next.t(localStorage.getItem("developerMode")==="true"?'developer.disable':'developer.enable')}</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export const DeveloperPage = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_DeveloperPage)
);