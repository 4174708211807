import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Select.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Icon } from './Icon';
import i18next from 'i18next';

function mapStateToProps({ field }) {
  return {
    validation: field
  };
}

const mapDispatchToProps = dispatch => {
  return {
  };
};

class _Select extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string, 
    options: PropTypes.array,
    style: PropTypes.object
  };
  onValueChange = event => {
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  }
  render() {
    const local = i18next.t('validationMsg', {returnObjects: true});
    return (
        <React.Fragment>
          <div className='dd_container'>
            <p className={classNames("dd_information_text", this.props.class ? "dd_" + this.props.class : "dd_pink")}>{this.props.title || 'Dropdown'}</p>
            <select
              className="dd_select"
              name={this.props.name}
              disabled={this.props.disabled && true}
              onChange={this.onValueChange}
              style={{ color: this.props.value ? "" : "gray"}}
              value={this.props.value}
            >
              {this.props.options && this.props.options.map((item, index) => {
                const props = { key: index, value: item.value };
                return (
                  <option {...props} key={index}>
                    {item.text}
                  </option>
                );
              })}
            </select>
            <div className="dd_icon">
              <Icon>direction-down</Icon>
            </div>
            {
              this.props.validation[this.props.name] && this.props.validation[this.props.name].map((v, i) => {
                return(<div key={i}  className="error_message">{local[v]}</div>)
              })
            }
          </div>
        </React.Fragment>
    );
  }
}

export const Select = withRouter(
  connect(
      mapStateToProps,
      mapDispatchToProps
  )(_Select)
);