import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './MembersLoginPage.css';
import { validate } from '../../Validation';
import { OpenPopup, resetPropMessages, setPropMessages, UpdateSignUpValue, UpdateUserLoginForm, UserNotFound, UserSignup, UserTryAgain } from '../../Actions';
import i18next from 'i18next';
import GoogleButton from 'react-google-button';
import { Button, Progressbar, TextBox } from '../../Components';
import { baseUrl, frontUrl } from '../../Actions/utilActions';

function mapStateToProps({ app, user }) {
    return { app, user };
}

const mapDispatchToProps = dispatch => {
    return {
        openPopup: data => {
            dispatch(OpenPopup(data));
        },
        updateValue: data => {
            dispatch(UpdateUserLoginForm(data));
        },
        updateSignupValue: data => {
            dispatch(UpdateSignUpValue(data));
        },
        signup: data => {
            dispatch(UserSignup(data));
        },
        setPropMessages: (key, error) => {
            dispatch(setPropMessages(key, error));
        },
        resetPropMessages: key => {
            dispatch(resetPropMessages(key));
        },
        tryAgain: () => {
            dispatch(UserTryAgain());
        },
        usernotfound: (email) => {
            dispatch(UserNotFound(email));
        }
    };
};

class _MembersLoginPage extends Component {
    static propTypes = {
        user: PropTypes.object,
        location: PropTypes.object,
        history: PropTypes.object
    };
    constructor(props) {
        super(props);
        this.state = {
            change: false,
            loginInprogress: false,
            editAbout: false,
            page: "login",
        }
    }
    valid(data, validator) {
        var valid = true;
        Object.keys(validator).forEach(key => {
            const validation = validate(data, key, validator[key]);
            if (!validation.valid) {
                this.props.setPropMessages(key, validation.errors);
                valid = false;
            } else {
                this.props.resetPropMessages(key);
            }
        });
        return valid;
    }
    updateValueOnChange(e) {
        this.props.updateValue({ [e.target.name]: e.target.value });
    }
    componentDidUpdate() {
        if (this.props.user.user) {
            this.props.history.push("/mypage")
        }
    }
    updateSignupValue(e) {
        this.props.updateSignupValue({ [e.target.name]: e.target.value });
    }
    signup() {
        var valid = true;
        if (this.valid(this.props.user.signup, { email: ['email', 'mandatory'], password: ['passwordStrength', 'mandatory'], confirmpassword: ['passwordMatch', 'mandatory'] })) {
            if (valid) {
                this.props.signup(this.props.user.signup);
            } else {
                return;
            }
        }
    }
    render() {
        if (this.props.user.user?.email === "not verified") { // TODO code has to be re-worked
            return (
                <div className="register_container">
                    <div className='register_page_header'>
                        <h3>{i18next.t('menu.memberlogin')}</h3>
                    </div>
                    <div className='login_error'>
                        <div className='personal_details_row middle_align'>
                            <p style={{ textAlign: 'center' }}>
                                Please verify your email address by clicking the verification link sent to your email <b>{this.props.user.user.email}</b>. <br />
                            </p>
                        </div>
                        <div className='personal_details_row middle_align'>
                            <Button onClick={() => { this.props.tryAgain() }}>Try with different Login.</Button>
                        </div>
                    </div>
                </div>
            );
        }
        if (this.props.user.user_error !== "" || this.props.user.user_not_approved !== "") {
            return (
                <div className="register_container">
                    <div className='register_page_header'>
                        <h3>{i18next.t('menu.memberlogin')}</h3>
                    </div>
                    <div className='login_error'>
                        <div className='personal_details_row middle_align'>
                            <p style={{ textAlign: 'center' }}>
                                {this.props.user.user_error !== "" && <>User details are not found for this email address <b>{this.props.user.user_error}</b>. <br /></>}
                                {this.props.user.user_not_approved !== "" && <>Your registration request with email <b>{this.props.user.user_not_approved}</b> not yet approved.<br /></>}
                                If you are a member, Please contact board members to get access. <br />
                                If you want to become a member please use the registration form. <br />
                            </p>
                        </div>
                        <div className='personal_details_row middle_align'>
                            <Button onClick={() => { this.props.tryAgain() }}>Try with different Login.</Button>
                        </div>
                    </div>
                </div>
            );
        }
        if (localStorage.getItem("ats_init_login") === "true") {
            return (
                <div className="register_container">
                    <div className='profile_page'>
                        <Progressbar />
                    </div>
                </div>
            );
        }
        return (
            <div className="register_container">
                {
                    this.props.user.signup.completed &&
                    <div className="register_container">
                        <div className='register_page_header'>
                            <h3>{i18next.t('signup.completed')}</h3>
                        </div>
                        <div className='register_form_result register_form_success'>
                            We have sent you an email confirming your sign up. <br/> 
                            Please check your email and click on the activation link to confirm your email address.
                        </div>
                        <div style={{display: 'flex', justifyContent:'center', alignItems:'center'}}>
                            <Button onClick={() => { this.props.updateSignupValue({ completed: false });this.setState({ page: "login" }) }}>{i18next.t('signup.gotosignin')}</Button>
                        </div>
                    </div>
                }
                {
                    !this.props.user.signup.completed &&
                    <React.Fragment>
                        {
                            this.state.page === "login" &&
                            <>
                                <div className='register_page_header'>
                                    <h3>{i18next.t('menu.memberlogin')}</h3>
                                </div>
                                <div className='login_details'>
                                    <TextBox name="email" type="text" className="style1" value={this.props.user.login.email} title={i18next.t('register.email')} placeholder={i18next.t('register.email').replace(" *", "")} onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                                    <TextBox name="password" type="password" className="style1" value={this.props.user.login.password} title={i18next.t('register.password')} placeholder={i18next.t('register.password').replace(" *", "")} onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                                    {!this.state.loginInprogress && <Button onClick={() => { }} className="login_button">{i18next.t('register.signin')}</Button>}
                                    <div className='forgot_password'>
                                        <div className='forgot_password_link' onClick={() => { this.setState({ page: "forgotpassword" }) }}>{i18next.t('register.forgotpassword')}</div>
                                    </div>
                                    <div className='or-connect'>
                                        or connect with your social media
                                    </div>
                                    {this.props.user.user === null && <GoogleButton style={{ width: "100%" }} onClick={() => { window.open(baseUrl + "/oauth2/authorize/google?redirect_uri=" + frontUrl + "/redirect", "_self") }} />}
                                    <div className='need_signup'>
                                        {"Need an account? "}<div className='signup_link' onClick={() => { this.setState({ page: "signup" }) }}>SIGN UP</div>
                                    </div>
                                </div>
                            </>
                        }
                        {
                            this.state.page === "signup" &&
                            <>
                                <div className='register_page_header'>
                                    <h3>{i18next.t('menu.createaccount')}</h3>
                                </div>
                                <div className='login_details'>
                                    <TextBox name="email" type="text" className="style1" value={this.props.user.signup.email} title={i18next.t('register.email')} placeholder={i18next.t('register.email').replace(" *", "")} onChange={(e) => this.updateSignupValue(e)}></TextBox>
                                    <TextBox name="password" type="password" className="style1" value={this.props.user.signup.password} title={i18next.t('register.password')} placeholder={i18next.t('register.password').replace(" *", "")} onChange={(e) => this.updateSignupValue(e)}></TextBox>
                                    <TextBox name="confirmpassword" type="password" className="style1" value={this.props.user.signup.confirmpassword} title={i18next.t('register.confirmpassword')} placeholder={i18next.t('register.confirmpassword').replace(" *", "")} onChange={(e) => this.updateSignupValue(e)}></TextBox>
                                    {!this.state.loginInprogress && <Button onClick={() => { this.signup() }} className="login_button">{i18next.t('register.signup')}</Button>}
                                    <div className='need_signup'>
                                        {"Already have an account? "}<div className='signup_link' onClick={() => { this.setState({ page: "login" }) }}>SIGN IN</div>
                                    </div>
                                    {
                                        this.props.user.signup.error &&
                                        <div className='error_message'>
                                            {this.props.user.signup.error}
                                        </div>
                                    }
                                </div>
                            </>
                        }
                        {
                            this.state.page === "forgotpassword" &&
                            <>
                                <div className='register_page_header'>
                                    <h3>{i18next.t('register.forgotpassword')}</h3>
                                </div>
                                <div className='login_details'>
                                    <TextBox name="email" type="text" className="style1" value={this.props.user.login.email} title={i18next.t('register.email')} placeholder={i18next.t('register.email').replace(" *", "")} onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                                    <Button onClick={() => { }} className="login_button">{i18next.t('register.proceed')}</Button>
                                    <Button onClick={() => { this.setState({ page: "login" }) }} className="login_button">{i18next.t('register.cancel')}</Button>
                                </div>
                            </>
                        }
                    </React.Fragment>
                }
            </div>
        );
    }
}

export const MembersLoginPage = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_MembersLoginPage)
);