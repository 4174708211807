// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ico_icon {
    width: 24px;
    height: 24px;
    fill: currentColor;
    fill-rule: evenodd;
    stroke: none;
    vertical-align: middle;
  }
  .XSmall {
    width: 13px;
    height: 13px;
  }
  .Small {
    width: 20px;
    height: 20px;
  }
  .Medium {
    width: 24px;
    height: 24px;
  }
  .Normal {
    width: 30px;
    height: 30px;
  }
  .Large {
    width: 48px;
    height: 48px;
  }
  .XLarge {
    width: 72px;
    height: 72px;
  }
  .XXLarge {
    width: 896px;
    height: 802px;
  }
  .Rotate {
    transform-origin: 50% 50%;
    animation: rotate 1.5s ease-in-out infinite;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0);
      opacity: 1;
    }
    50% {
      transform: rotate(180deg);
      opacity: 0.9;
    }
    100% {
      transform: rotate(360deg);
      opacity: 1;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/BasicComponents/Icon.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;IACZ,sBAAsB;EACxB;EACA;IACE,WAAW;IACX,YAAY;EACd;EACA;IACE,WAAW;IACX,YAAY;EACd;EACA;IACE,WAAW;IACX,YAAY;EACd;EACA;IACE,WAAW;IACX,YAAY;EACd;EACA;IACE,WAAW;IACX,YAAY;EACd;EACA;IACE,WAAW;IACX,YAAY;EACd;EACA;IACE,YAAY;IACZ,aAAa;EACf;EACA;IACE,yBAAyB;IACzB,2CAA2C;EAC7C;;EAEA;IACE;MACE,oBAAoB;MACpB,UAAU;IACZ;IACA;MACE,yBAAyB;MACzB,YAAY;IACd;IACA;MACE,yBAAyB;MACzB,UAAU;IACZ;EACF","sourcesContent":[".ico_icon {\r\n    width: 24px;\r\n    height: 24px;\r\n    fill: currentColor;\r\n    fill-rule: evenodd;\r\n    stroke: none;\r\n    vertical-align: middle;\r\n  }\r\n  .XSmall {\r\n    width: 13px;\r\n    height: 13px;\r\n  }\r\n  .Small {\r\n    width: 20px;\r\n    height: 20px;\r\n  }\r\n  .Medium {\r\n    width: 24px;\r\n    height: 24px;\r\n  }\r\n  .Normal {\r\n    width: 30px;\r\n    height: 30px;\r\n  }\r\n  .Large {\r\n    width: 48px;\r\n    height: 48px;\r\n  }\r\n  .XLarge {\r\n    width: 72px;\r\n    height: 72px;\r\n  }\r\n  .XXLarge {\r\n    width: 896px;\r\n    height: 802px;\r\n  }\r\n  .Rotate {\r\n    transform-origin: 50% 50%;\r\n    animation: rotate 1.5s ease-in-out infinite;\r\n  }\r\n  \r\n  @keyframes rotate {\r\n    0% {\r\n      transform: rotate(0);\r\n      opacity: 1;\r\n    }\r\n    50% {\r\n      transform: rotate(180deg);\r\n      opacity: 0.9;\r\n    }\r\n    100% {\r\n      transform: rotate(360deg);\r\n      opacity: 1;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
