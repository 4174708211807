function prefix(action) {
  return `FIELDS_${action}`;
}

export const FieldsValidationsActions = {
  SET_PROP_MESSAGES: prefix('SET_PROP_MESSAGES'),
  RESET_PROP_MESSAGES: prefix('RESET_PROP_MESSAGES'),
  RESET_ALL_PROP_MESSAGES: prefix('RESET_ALL_PROP_MESSAGES')
};

export const setPropMessages = (propName, messages) => dispatch => {
  dispatch({
    type: FieldsValidationsActions.SET_PROP_MESSAGES,
    payload: {
      name: propName,
      messages: messages
    }
  });
};

export const resetPropMessages = propName => {
  return {
    type: FieldsValidationsActions.RESET_PROP_MESSAGES,
    payload: {
      name: propName
    }
  };
};

export const resetAllPropMessages = () => {
  return {
    type: FieldsValidationsActions.RESET_ALL_PROP_MESSAGES
  };
};