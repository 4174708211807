import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import loading from '../Images/loading.svg';
import './Route.css';
import { Header } from '../Components/Header/Header';
import { PrivacyNotice } from '../Pages';
import { Footer } from '../Components/Footer/Footer';
// import i18next from 'i18next';
import { ChangeLanguage } from '../Actions';
import { Popup } from '../Components';
// import { GoogleAuthProvider, getAuth, getRedirectResult, onAuthStateChanged} from "firebase/auth";
// import { InagurationPage } from '../Pages/InagurationPage/InagurationPage';
// import { InagurationAdminPage } from '../Pages/InagurationAdminPage/InagurationAdminPage';

function mapStateToProps({ app, user }) {
  return { app, user };
}

const mapDispatchToProps = dispatch => {
  return {
    selectLanguage: lang => {
        dispatch(ChangeLanguage(lang));
    }
    // status: () => {
    //     dispatch(GetSiteInagurationStatus());
    // }
  };
};

class _PrivateRoute extends Component {
  static propTypes = {
    component: PropTypes.any.isRequired,
    path: PropTypes.string,
    app: PropTypes.object,
    user: PropTypes.object,
    openPopup: PropTypes.func,
    location: PropTypes.object,
    history: PropTypes.object
  };
  constructor(props) {
      super(props);
      this.state = {
        privacyShow: false
      }
  }
  componentDidMount(){
    if (this.props.user.user !== null){

    } else {
      this.props.history.push("/mypage");
    }
  }
  componentDidUpdate(){
    if (this.props.user.user !== null){

    } else {
      this.props.history.push("/");
    }
    if (this.props.location.pathname !== "/error" && this.props.app.errorCode !== null){
      this.props.history.push("/error");
    }
  }
  privacy(){
    this.setState({ privacyShow: true });
  }
  closePrivacy(){
    this.setState({ privacyShow: false });
  }
  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        path={this.props.path}
        render={matchProps => (
          this.props.app.language &&
          <React.Fragment>
            {
              // (this.props.app.inagurationStatus && this.props.app.inagurationStatus["website-started"]) && 
              <div className="public_page">
                <div className='public_page_header'>
                  <Header />
                </div>
                <div className='public_page_body'>
                  <Component {...matchProps} />
                </div>
                <div className='public_page_footer'>
                  <Footer />
                </div>
              </div>
            }
            {/* {
              localStorage.getItem("ATS_ADMIN") === "Neethirajan" &&
              <InagurationAdminPage></InagurationAdminPage>
            }
            {
              (this.props.app.inagurationStatus && !this.props.app.inagurationStatus["website-started"] && localStorage.getItem("ATS_ADMIN") !== "Neethirajan" ) && 
              <InagurationPage></InagurationPage>
            } */}
            <Popup open={this.props.app.showProgressBar} noClose>
              <div style={{ zIndex: 200, width: '200px', height: '200px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <img src={loading} style={{ width: '100%', height: '100%' }} alt="loading..." />
              </div>
            </Popup>
            {
              this.props.location.pathname !== "/privacypolicy" && 
              <PrivacyNotice show={this.state.privacyShow} close={()=>{this.closePrivacy()}}/>
            }
          </React.Fragment>
        )}
      />
    );
  }
}

export const PrivateRoute = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(_PrivateRoute)
);