import moment from "moment";
import { jwtDecode } from "jwt-decode";
import { environment } from "./env";

export const baseUrl = environment === "dev" ? "http://localhost:8080" : "https://almhulttamilsangam.se";
export const frontUrl = environment === "dev" ? "http://localhost:3000" : "https://almhulttamilsangam.se";

export const storage = localStorage;

export const accessTokenKey = 'access_token';
export const refreshTokenKey = 'refresh_token';
export const errorKey = 'error_message';

export function getToken(key) {
  return storage.getItem(key);
}

export function setToken(key, token) {
  storage.setItem(key, token);
}

export function clearToken() {
  storage.removeItem(accessTokenKey);
  storage.removeItem(refreshTokenKey);
}

export function storeToken(jwt) {
  if (jwt && jwt[accessTokenKey]) {
    const accessToken = jwt[accessTokenKey];
    if (jwt[refreshTokenKey]) {
      setToken(refreshTokenKey, jwt[refreshTokenKey]);
    }
    setToken(accessTokenKey, accessToken);
    return accessToken;
  }
  console.log('token invalid');
  return null;
}

export function chekuserlogin(props) {
  var token = getToken(accessTokenKey);
  console.log("token:",token)
  if (token !== null && token !== "") {
    var jwt = jwtDecode(token);
    if (jwt.exp === undefined || moment(jwt.exp * 1000) < moment()) {
      props.logout();
      props.history.push('/login.html');
    }
    if (props.user?.loggedUser === null) {
      props.loadUser();
    }
  } else if ((props.user === null || !props.user?.loggedUser) && !['/login.html', 'login', '/', ''].includes(props.location.pathname)) {
    props.logout();
    props.history.push('/login.html');
  }
  if (props.user?.loggedUser && ['/login.html', 'login', '/', ''].includes(props.location.pathname)) {
    props.history.push('/overview');
  }
}

export function errormessageHandler(actionType, error, dispatch) {
  var errorPreview = {
    request: actionType,
    code: 500,
    message: "Internal Server Error",
  }
  if ((error + "") === "Error: Network Error") {
    errorPreview = {
      code: "API Not working.",
      request: actionType,
      message: "Internal Server Error",
      description: "Please check network connection on the server."
    }
  } else{
    errorPreview = {
      code: 2,
      request: actionType,
      message: "Internal Server Error",
      description : error.response?.data?.error_description
    }
  }
  dispatch({ type: actionType, payload: errorPreview });
}