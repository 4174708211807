import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CheckActivateAccount } from '../Actions';
import { Button } from '../Components';
import i18next from 'i18next';

function mapStateToProps({ app, user }) {
    return { app, user };
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        check: (data) =>{
            dispatch(CheckActivateAccount(data));
        }
    };
  };
  
class _ActivateAccount extends Component {
    componentDidMount(){
        const email = new URLSearchParams(this.props.location.search).get("email");
        const code =new URLSearchParams(this.props.location.search).get("code");
        if (email && code){
            this.props.check({email: email, code: code});
        }
    }
    componentDidUpdate(){
        if (this.props.user.user !== null){
            this.props.history.push("/mypage");
        }
    }
    render() {
        if (this.props.user.signup.activated){
            return(
                <div className="page_container">
                    <div className='register_form_result register_form_success'>
                        Your account is now successfully activated. <br/> 
                        Please use the sign in page to access your account now.
                    </div>
                    <div style={{display: 'flex', justifyContent:'center', alignItems:'center'}}>
                        <Button onClick={() => this.props.history.push("/memberlogin")}>{i18next.t('signup.gotosignin')}</Button>
                    </div>
                </div>
            )
        }
        const email = new URLSearchParams(this.props.location.search).get("email");
        const error =new URLSearchParams(this.props.location.search).get("error") || this.props.user.signup.error;
        if(!error) {
            return (
                <div className="page_container">
                    <div className='make_center'>
                        <div>Activating your account {email}...</div>
                    </div>
                </div>
            ); 
        } else {
            return (
                <div className="page_container">
                    <div className='make_center'>
                    <div>{error}</div>
                    </div>
                    <div style={{display: 'flex', justifyContent:'center', alignItems:'center'}}>
                        <Button onClick={() => this.props.history.push("/memberlogin")}>{i18next.t('signup.gotosignin')}</Button>
                    </div>
                </div>
            ); 
        }
    }
}
export const ActivateAccount = withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(_ActivateAccount)
  );