// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.txt_container{
    display: block;
    font-size: 14px;
}
.txt_input{
    border: 1px solid #e9ddd0;
    background-color: white;
    width: 300px;
    height: 30px;
    padding: 2px 10px;
    font-size: 18px;
    border-radius: 0;
}
.txt_input:focus{
    outline: none;
}
.txt_input:disabled{
    background-color: #f2f2f2;
}
.txt_information_text{
    margin: 0;
    padding: 3px;
    color: black;
    display: flex;
}
.style1{
    border-radius: 5px;
    padding: 5px 10px;
    width: calc(100% - 20px);
}


@media (max-width:576px){
    .txt_input{ width: 100%; }
}
@media (max-width:768px) and (min-width:576px){
    .txt_input{ width: 100%; }
}
@media screen and (orientation:landscape) and (max-width:992px) {
    .txt_input{ width: 100%; }
}`, "",{"version":3,"sources":["webpack://./src/Components/BasicComponents/TextBox.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;AACnB;AACA;IACI,yBAAyB;IACzB,uBAAuB;IACvB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,aAAa;AACjB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,SAAS;IACT,YAAY;IACZ,YAAY;IACZ,aAAa;AACjB;AACA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,wBAAwB;AAC5B;;;AAGA;IACI,YAAY,WAAW,EAAE;AAC7B;AACA;IACI,YAAY,WAAW,EAAE;AAC7B;AACA;IACI,YAAY,WAAW,EAAE;AAC7B","sourcesContent":[".txt_container{\r\n    display: block;\r\n    font-size: 14px;\r\n}\r\n.txt_input{\r\n    border: 1px solid #e9ddd0;\r\n    background-color: white;\r\n    width: 300px;\r\n    height: 30px;\r\n    padding: 2px 10px;\r\n    font-size: 18px;\r\n    border-radius: 0;\r\n}\r\n.txt_input:focus{\r\n    outline: none;\r\n}\r\n.txt_input:disabled{\r\n    background-color: #f2f2f2;\r\n}\r\n.txt_information_text{\r\n    margin: 0;\r\n    padding: 3px;\r\n    color: black;\r\n    display: flex;\r\n}\r\n.style1{\r\n    border-radius: 5px;\r\n    padding: 5px 10px;\r\n    width: calc(100% - 20px);\r\n}\r\n\r\n\r\n@media (max-width:576px){\r\n    .txt_input{ width: 100%; }\r\n}\r\n@media (max-width:768px) and (min-width:576px){\r\n    .txt_input{ width: 100%; }\r\n}\r\n@media screen and (orientation:landscape) and (max-width:992px) {\r\n    .txt_input{ width: 100%; }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
