import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './Diwali2024Page.css';

function mapStateToProps({ app, user }) {
    return {
        app: app,
        user: user
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

class _Diwali2024Page extends Component {
    static propTypes = {
        user: PropTypes.object,
        location: PropTypes.object,
        history: PropTypes.object
    };
    render() {
        return (
            <div className="diwali24_page_container">
                <div className="diwali24_page_content">
                    <div className="diwali24_text_content">
                        <h1>Diwali Celebration 2024</h1>
                    </div>
                    <div className="diwali24_image_container">
                        <img className='diwali24_image' alt="" src={require("../../Images/events/Diwali2024.jpeg")} />
                    </div>
                    <div className="diwali24_text_content">
                        <h2>All Indian Associations from Älmhult Togather with Älmhult Kommun presents</h2>
                        <p className='diwali24_p'>
                            <b>**Diwali Celebration 2024 in Älmhult Centrum**</b>
                        </p>
                        <br />
                        <br />
                        <p className='diwali24_p'>
                            Join us for a vibrant and joyful Diwali celebration in Älmhult Centrum! This open public event promises
                            a fun-filled day for families and friends, bringing together the community in the true spirit of Diwali.
                            Experience the lively atmosphere with <b>music and dance performances</b>, showcasing traditional and contemporary styles.</p>
                        <br />
                        <p className='diwali24_p'>
                            Indulge in a variety of <b>food and snack stalls</b> offering delicious treats from different cuisines, perfect for savoring the festive flavors.
                            There will also be <b>family games</b> and activities to keep everyone entertained throughout the day, with plenty more surprises in store.
                        </p>
                        <br />
                        <p className='diwali24_p'>Come celebrate Diwali with us—a day of light, laughter, and togetherness!</p>
                        <br />
                        <br />
                    </div>
                </div>
            </div>
        );
    }
}

export const Diwali2024Page = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_Diwali2024Page)
);