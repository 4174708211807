import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './HomePage.css';
import image1 from '../../Images/scroll_image1.png';
import image2 from '../../Images/scroll_image2.png';
import image3 from '../../Images/scroll_image3.png';
import image4 from '../../Images/scroll_image4.png';
import image5 from '../../Images/scroll_image5.png';
import { Slider } from '../../Components';
import i18next from 'i18next';
import moment from 'moment';

function mapStateToProps({ app, user }) {
    return {
        app: app,
        user: user
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

class _HomePage extends Component {
    static propTypes = {
        user: PropTypes.object,
        location: PropTypes.object,
        history: PropTypes.object
    };
    render() {
        return (
            <div className="home_selection">
                <Slider slides={[image5, image1, image2, image3, image4]}></Slider>
                {
                    (moment() < moment("2024-11-10")) &&
                    <div className='home_section_container'>
                        <div className='upcoming_event'>
                            <div>
                                <div className='home_upc_event_header'>
                                    <h3>Our upcoming event</h3>
                                </div>
                                <div className='home_upc_event_name'>
                                    <h2 onClick={() => { this.props.history.push("diwali2024") }}>Diwali Celebration 2024</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className='home_section_container'>
                    <div className='intro_main'>
                        <div style={{ display: 'block' }}>
                            <h3>{i18next.t('intro.title')}</h3>
                            <p className='intro_content'>
                                {i18next.t('intro.para1')}
                            </p>
                            <br />
                            <p className='intro_content'>
                                {i18next.t('intro.para2')}
                            </p>
                            <h4>
                                {i18next.t('intro.activityHeader')}
                            </h4>
                            <ul>
                                {
                                    i18next.t('intro.activityList', { returnObjects: true })?.map((x, i) => {
                                        return (<li key={i}>{x} {
                                            i === 3 &&
                                            <ul>
                                                {
                                                    i18next.t('intro.subactivityList', { returnObjects: true })?.map((x, i) => {
                                                        return (<li key={i}>{x}</li>)
                                                    })
                                                }
                                            </ul>
                                        }</li>)
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <div className='home_section_container'>
                    <div className='sponsor_container'>
                        Our Sponsors
                    </div>
                </div> */}
            </div>
        );
    }
}

export const HomePage = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_HomePage)
);