// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header{
    font-size: 45px;
    padding-bottom: 8px;
    margin-bottom: 4px;
}
.header1{
    font-size: 32px;
    padding-bottom: 8px;
    margin-bottom: 4px;
}
.header3{
    font-size:20px;
    padding-bottom: 8px;
    margin-bottom: 4px; 
}
.p{
    font-size: 18px;
    font-weight: normal;
    padding-bottom: 8px;
    margin-bottom: 4px;
    text-align: justify;
}
.li{
    font-size: 18px;
    font-weight: normal;
    padding-bottom: 8px;
    margin-bottom: 4px;
}
.a{
    color: black;
    text-decoration: underline;
}

.pp_container{
    color: #1D3557;
    font-weight: bold;
    padding-bottom: 4px;
    padding-right:15px;
    padding-left:15px;
    margin:auto;
    text-align: left;
}
@media (min-width:576px){.pp_container{width:540px}}
@media (min-width:768px){.pp_container{width:720px}}
@media (min-width:992px){.pp_container{width:960px}}
@media (min-width:1200px){.pp_container{width:1140px}}`, "",{"version":3,"sources":["webpack://./src/Pages/PrivacyPolicy/Privacypolicy.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,cAAc;IACd,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,0BAA0B;AAC9B;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,gBAAgB;AACpB;AACA,yBAAyB,cAAc,WAAW,CAAC;AACnD,yBAAyB,cAAc,WAAW,CAAC;AACnD,yBAAyB,cAAc,WAAW,CAAC;AACnD,0BAA0B,cAAc,YAAY,CAAC","sourcesContent":[".header{\r\n    font-size: 45px;\r\n    padding-bottom: 8px;\r\n    margin-bottom: 4px;\r\n}\r\n.header1{\r\n    font-size: 32px;\r\n    padding-bottom: 8px;\r\n    margin-bottom: 4px;\r\n}\r\n.header3{\r\n    font-size:20px;\r\n    padding-bottom: 8px;\r\n    margin-bottom: 4px; \r\n}\r\n.p{\r\n    font-size: 18px;\r\n    font-weight: normal;\r\n    padding-bottom: 8px;\r\n    margin-bottom: 4px;\r\n    text-align: justify;\r\n}\r\n.li{\r\n    font-size: 18px;\r\n    font-weight: normal;\r\n    padding-bottom: 8px;\r\n    margin-bottom: 4px;\r\n}\r\n.a{\r\n    color: black;\r\n    text-decoration: underline;\r\n}\r\n\r\n.pp_container{\r\n    color: #1D3557;\r\n    font-weight: bold;\r\n    padding-bottom: 4px;\r\n    padding-right:15px;\r\n    padding-left:15px;\r\n    margin:auto;\r\n    text-align: left;\r\n}\r\n@media (min-width:576px){.pp_container{width:540px}}\r\n@media (min-width:768px){.pp_container{width:720px}}\r\n@media (min-width:992px){.pp_container{width:960px}}\r\n@media (min-width:1200px){.pp_container{width:1140px}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
