// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mh_popup{
    position: relative;
    z-index: 100;
    right: 10px;
    float: right;
}
.mh_popup_body{
    position: absolute;
    top: 100%;
    right: 0;
    left: auto;
    white-space: nowrap;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    border-radius: 0.25rem;
    padding: 5px 0;
}
.mh_item{
    padding: 10px;
    color: #212529;
}
.mh_item:focus, .mh_item:hover{
    cursor: pointer;
    background-color: #F0F0F0;
}
.mh_background{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99;
}
.mh_selected{
    background-color: lightgray;
}`, "",{"version":3,"sources":["webpack://./src/Components/BasicComponents/MenuHolder.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,YAAY;AAChB;AACA;IACI,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,UAAU;IACV,mBAAmB;IACnB,oBAAoB;IACpB,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,gBAAgB;IAChB,sBAAsB;IACtB,4BAA4B;IAC5B,uEAAuE;IACvE,sBAAsB;IACtB,cAAc;AAClB;AACA;IACI,aAAa;IACb,cAAc;AAClB;AACA;IACI,eAAe;IACf,yBAAyB;AAC7B;AACA;IACI,eAAe;IACf,MAAM;IACN,SAAS;IACT,QAAQ;IACR,OAAO;IACP,WAAW;AACf;AACA;IACI,2BAA2B;AAC/B","sourcesContent":[".mh_popup{\r\n    position: relative;\r\n    z-index: 100;\r\n    right: 10px;\r\n    float: right;\r\n}\r\n.mh_popup_body{\r\n    position: absolute;\r\n    top: 100%;\r\n    right: 0;\r\n    left: auto;\r\n    white-space: nowrap;\r\n    margin: 0.125rem 0 0;\r\n    font-size: 1rem;\r\n    color: #212529;\r\n    text-align: left;\r\n    list-style: none;\r\n    background-color: #fff;\r\n    background-clip: padding-box;\r\n    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);\r\n    border-radius: 0.25rem;\r\n    padding: 5px 0;\r\n}\r\n.mh_item{\r\n    padding: 10px;\r\n    color: #212529;\r\n}\r\n.mh_item:focus, .mh_item:hover{\r\n    cursor: pointer;\r\n    background-color: #F0F0F0;\r\n}\r\n.mh_background{\r\n    position: fixed;\r\n    top: 0;\r\n    bottom: 0;\r\n    right: 0;\r\n    left: 0;\r\n    z-index: 99;\r\n}\r\n.mh_selected{\r\n    background-color: lightgray;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
