import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './Reducers';
import { environment } from './Actions/env';

const initalState = {};
const middleware = [thunk];

let store;
if (window.navigator.userAgent.indexOf('Chrome') > -1 && window.location.hostname === (environment === "dev" ? 'localhost' : "prod")) {
  store = createStore(
    rootReducer,
    initalState,
    compose(
      applyMiddleware(...middleware),
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  );
} else {
  store = createStore(
    rootReducer,
    initalState,
    compose(applyMiddleware(...middleware))
  );
}

export default store;