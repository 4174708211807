import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './Header.css';
import { ChangeLanguage, LogoutUser } from '../../Actions';
import logo from '../../Images/ATS-Logo.png';
import { Icon } from '../BasicComponents/Icon';
import { MenuHolder } from '../BasicComponents/MenuHolder';
import i18next from 'i18next';
import menu from '../../Assets/menu.json';
import profilePic from "../../Images/user.png";
import { showDev } from '../../Utils/utils';
import moment from 'moment';

function mapStateToProps({ app, user, game }) {
    return { app, user, game };
}

const mapDispatchToProps = dispatch => {
    return {
        selectLanguage: lang => {
            dispatch(ChangeLanguage(lang));
        },
        logout: () => {
            dispatch(LogoutUser());
        }
    };
};

class _Header extends Component {
    static propTypes = {
        user: PropTypes.object,
        location: PropTypes.object,
        history: PropTypes.object
    };
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            openProfileMenu: false,
            openMenuMobile: false,
            submenu: ""
        }
    }
    openMenu(menu) {
        if (menu.submenu && this.state.submenu !== menu.name) {
            this.setState({ submenu: menu.name });
        } else if (menu.submenu && this.state.submenu === menu.name) {
            this.setState({ submenu: "" });
        } else {
            this.props.history.push(menu.location);
            this.setState({ menuOpen: false, openMenuMobile: false });
        }
    }
    openSubMenu(submenu) {
        this.props.history.push(submenu.location);
        this.setState({ submenu: "", menuOpen: false, openMenuMobile: false });
    }
    getMenuItems(type) {
        var user = this.props.user.user?.familyUsers.filter(x => x.id === this.props.user.user.id)[0];
        return menu.filter(x => showDev(x, user)).map((x, i) => {
            return (
                <React.Fragment key={i}>
                    {
                        (i !== 0 && type === 'desktop') && <div key={"i" + i} className='header_menu_breakline'>|</div>
                    }
                    <div key={i} className={type === 'mobile' ? 'header_menuitem_mobile' : 'header_menuitem'} onClick={() => { this.openMenu(x) }}>
                        {i18next.t(x.name)}
                        {
                            x.submenu &&
                            <div style={{ marginLeft: 10 }}><Icon>{this.state.submenu === "" ? "direction-down" : "direction-up"}</Icon></div>
                        }
                    </div>
                    {
                        (x.submenu && type === 'desktop') &&
                        <MenuHolder open={this.state.submenu === x.name} closePopup={() => this.setState({ submenu: "" })} top={20} outsideClickClose>
                            <div className="language_list">
                                {
                                    x.submenu.filter(z => showDev(z, user)).map((y, j) => {
                                        return (
                                            <div key={j} className="language_item" onClick={() => { this.openSubMenu(y) }}>
                                                {i18next.t(y.name)}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </MenuHolder>
                    }
                    {
                        (x.submenu && type === 'mobile') &&
                        x.submenu.filter(z => showDev(z, user)).map((y, j) => {
                            return (
                                <div key={j} className={'header_menuitem_mobile'} style={{ display: this.state.submenu === x.name ? "" : "none" }} onClick={() => { this.openSubMenu(y) }}>
                                    {i18next.t(y.name)}
                                </div>
                            )
                        })
                    }
                </React.Fragment>
            )
        });
    }
    getUserLogin(type) {
        var user = this.props.user.user?.familyUsers.filter(x => x.id === this.props.user.user.id)[0];
        var login = user !== null && user !== undefined;
        if (login){
            return (
                <>
                    <div className={type === 'desktop' ? "user_profile_div" : "user_profile_div_mobile"} onClick={() => this.setState({ openProfileMenu: true })}>
                        <img src={user.pic || profilePic} alt={user.displayName} className="user_profile"></img>
                        {
                            type === 'mobile' &&
                            <>
                                <div  style={{display: 'flex'}}>
                                    <div className='last_login'>
                                        <div style={{ fontWeight: 'bold' }}>Last Login:</div>
                                        <div style={{ fontWeight: 'normal' }}>{moment(user?.lastLogin).format("LLL")}</div>
    
                                    </div>
                                    <div className="language_item logout" onClick={() => { this.props.logout(); this.setState({ openProfileMenu: false }); }}>
                                        Logout
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    {
                        type === 'desktop' &&
                        <MenuHolder open={this.state.openProfileMenu} closePopup={() => this.setState({ openProfileMenu: false })} top={0} outsideClickClose>
                            <div className="language_list">
                                <div  className='last_login'>
                                    <div style={{ fontWeight: 'bold' }}>Last Login:</div>
                                    <div style={{ fontWeight: 'normal' }}>{moment(user.lastLogin).format("LLL")}</div>
    
                                </div>
                                <div className="language_item" onClick={() => { this.props.logout(); this.setState({ openProfileMenu: false }); }}>
                                    Logout
                                </div>
                            </div>
                        </MenuHolder>
                    }
                </>
            );
        } else return null;
    }
    getLanguage(type) {
        return (
            <div className={type === 'desktop' ? 'header_lang_icon' : 'header_menuitem_mobile'}>
                <div className={'language_icon'} onClick={() => this.setState({ langMenu: true })}>
                    <Icon>language</Icon>
                </div>
                {
                    type === 'desktop' ?
                        <MenuHolder open={this.state.langMenu} closePopup={() => this.setState({ langMenu: false })} top={0} outsideClickClose>
                            <div className="language_list">
                                {
                                    [{ name: 'தமிழ்', id: 'ta' }, { name: 'English', id: 'en' }, { name: 'Svenska', id: 'sv' }].map((x, i) => {
                                        return (
                                            <div key={i} className="language_item" onClick={() => { i18next.changeLanguage(x.id); this.props.selectLanguage(x.id); this.setState({ langMenu: false }) }}>
                                                {x.name}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </MenuHolder> :
                        <div className='language_list_mobile'>
                            {
                                [{ name: 'தமிழ்', id: 'ta' }, { name: 'English', id: 'en' }, { name: 'Svenska', id: 'sv' }].map((x, i) => {
                                    return (
                                        <div key={i} className="language_item_mobile" onClick={() => { i18next.changeLanguage(x.id); this.props.selectLanguage(x.id); this.setState({ langMenu: false, menuOpen: false, openMenuMobile: false }) }}>
                                            {x.name}
                                        </div>
                                    )
                                })
                            }
                        </div>
                }
            </div>
        );
    }
    render() {
        var user = this.props.user.user?.familyUsers.filter(x => x.id === this.props.user.user.id)[0];
        var login = user !== null && user !== undefined;
        return (
            <React.Fragment>
                <div className="header_container">
                    <div className='header_logo' onClick={() => { this.props.history.push('/') }}>
                        <img alt='BAPA' src={logo} className="header_logo_img"></img>
                    </div>
                    <div className='header_title' onClick={() => { this.props.history.push('/') }}>
                        <h3>{i18next.t('app.ass_name')}</h3>
                    </div>
                    <div className='header_empty_middle'>
                        <h3 className='onlyMobile mobile_title'>{i18next.t('app.ass_name')}</h3>
                    </div>
                    <div className='header_menu_icon' onClick={() => this.setState({ openMenuMobile: !this.state.openMenuMobile })}>
                        <Icon>{this.state.openMenuMobile ? 'menuclose' : 'menu'}</Icon>
                    </div>
                    <div className='header_menulist'>
                        {this.getMenuItems('desktop')}
                        {login && this.getUserLogin('desktop')}
                        {!login && this.getLanguage('desktop')}
                    </div>
                </div>
                {
                    this.state.openMenuMobile &&
                    <div className='header_menulist_mobile'>
                        {this.getMenuItems('mobile')}
                        {this.getUserLogin('mobile')}
                        {this.getLanguage('mobile')}
                    </div>
                }
            </React.Fragment>
        );
    }
}

export const Header = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_Header)
);