import { AppActions } from "./appActions";
import Axios from "axios";
import { accessTokenKey, clearToken, getToken, baseUrl } from './utilActions';
import moment from "moment";
import { jwtDecode } from "jwt-decode";

function prefix(action) {
  return `USER_${action}`;
}

export const UserActions = {
  UPDATE: prefix('UPDATE'),
  UPDATE_LOGIN: prefix('UPDATE_LOGIN'),
  USER_UPDATE: prefix('USER_UPDATE'),
  USER_EDIT: prefix('USER_EDIT'),
  EDIT_ADDRESS: prefix('EDIT_ADDRESS'),
  EDIT_SPOUSE: prefix('EDIT_SPOUSE'),
  EDIT_KID: prefix('EDIT_KID'),
  USER_NOT_APPROVED: prefix('USER_NOT_APPROVED'),
  USER_UPDATE_ERROR: prefix('USER_UPDATE_ERROR'),
  USER_RESET_ERROR: prefix('USER_RESET_ERROR'),
  CONTACT: prefix('CONTACT'),
  CONTACT_CLR: prefix('CONTACT_CLR'),
  VERIFY: prefix('VERIFY'),
  REGISTER_SUCESS: prefix('REGISTER_SUCESS'),
  REGISTER_VALID_FAIL: prefix('REGISTER_VALID_FAIL'),
  REGISTER_FAIL: prefix('REGISTER_FAIL'),
  LOAD_ADMIN_PAGE: prefix('LOAD_ADMIN_PAGE'),
  QRCODE: prefix('QRCODE'),
  SIGNUP_UPD: prefix('SIGNUP_UPD'),
};

export const UpdateSignUpValue = data => dispatch => {
  dispatch({ type: UserActions.SIGNUP_UPD, payload: data });
};

export const UpdateUserEdit = data => dispatch => {
  dispatch({ type: UserActions.USER_EDIT, payload: data });
};

export const EditAddress = data => dispatch => {
  dispatch({ type: UserActions.EDIT_ADDRESS, payload: data });
};

export const EditSpouse = data => dispatch => {
  dispatch({ type: UserActions.EDIT_SPOUSE, payload: data });
};

export const EditKid = data => dispatch => {
  dispatch({ type: UserActions.EDIT_KID, payload: data });
};

export const UpdateUserRegisterValue = data => dispatch => {
  dispatch({ type: UserActions.UPDATE, payload: data });
};

export const UpdateUserLoginForm = data => dispatch => {
  dispatch({ type: UserActions.UPDATE_LOGIN, payload: data });
};

export const UpdateContactForm = data => dispatch => {
  dispatch({ type: UserActions.CONTACT, payload: data });
};

export const SubmitContactUs = (data) => dispatch => {
  Axios.post(`${baseUrl}/api/contactus/save`, data).then(result => {
    if (result.data === "Request Success.") {
      dispatch({ type: UserActions.CONTACT_CLR });
    }
  }).catch(error => {
    dispatch({ type: AppActions.ERROR, payload: { response: error.response, request: error } });
  });
}

export const RegisterMembership = (data) => dispatch => {
  Axios.post(`${baseUrl}/api/membership/register`, data).then(result => {
    console.log(result);
    if (result.data === "ÄTS Registration Success.") {
      dispatch({ type: UserActions.REGISTER_SUCESS });
    } else {
      dispatch({ type: UserActions.REGISTER_VALID_FAIL, payload: result.data });
    }
  }).catch(error => {
    // clearToken();
    // errormessageHandler(UserActions.LOGIN_FAIL,error,dispatch);
    dispatch({ type: UserActions.REGISTER_FAIL, payload: error });
  });
}

export const CheckandLoginUser = (data) => dispatch => {
  const token = getToken(accessTokenKey);
  if (token !== null && token !== "") {
    var jwt = jwtDecode(token);
    if (jwt.exp === undefined || moment(jwt.exp * 1000) < moment()) {
      dispatch(LogoutUser());
    } else {
      dispatch(LoginUser());
    }
  }
}

export const LoginUser = () => dispatch => {
  Axios.get(`${baseUrl}/api/member/login`, { headers: { authorization: "Bearer " + getToken(accessTokenKey) } }).then(result => {
    dispatch({ type: UserActions.USER_UPDATE, payload: result.data });
  }).catch(error => {
    // dispatch({ type: AppActions.ERROR, payload: { response: error.response, request: error } });
  });
}

export const LogoutUser = () => dispatch => {
  dispatch({ type: AppActions.SHOW_PB });
  clearToken();
  dispatch({ type: UserActions.USER_UPDATE, payload: null });
  dispatch({ type: AppActions.HIDE_PB });
}

export const UserNotFound = (email) => dispatch => {
  dispatch(LogoutUser());
  dispatch({ type: UserActions.USER_UPDATE_ERROR, payload: email });
}

export const UserTryAgain = () => dispatch => {
  dispatch(LogoutUser());
  dispatch({ type: UserActions.USER_RESET_ERROR });
}

export const LoadAdminPage = () => dispatch => {
  Axios.get(`${baseUrl}/api/admin/all`, { headers: { authorization: "Bearer " + getToken(accessTokenKey) } }).then(result => {
    dispatch({ type: UserActions.LOAD_ADMIN_PAGE, payload: result.data });
  }).catch(error => {
    dispatch({ type: AppActions.ERROR, payload: { response: error.response, request: error } });
  });
}

export const ApproveMembership = (data) => dispatch => {
  Axios.post(`${baseUrl}/api/admin/approve`, data, { headers: { authorization: "Bearer " + getToken(accessTokenKey) } }).then(result => {
    dispatch(LoadAdminPage());
  }).catch(error => {
    dispatch({ type: AppActions.ERROR, payload: { response: error.response, request: error } });
  });
}

export const RejectMembership = (data) => dispatch => {
  Axios.post(`${baseUrl}/api/admin/reject`, data, { headers: { authorization: "Bearer " + getToken(accessTokenKey) } }).then(result => {
    dispatch(LoadAdminPage());
  }).catch(error => {
    dispatch({ type: AppActions.ERROR, payload: { response: error.response, request: error } });
  });
}

export const UpdateProfileDetails = (data) => dispatch => {
  Axios.post(`${baseUrl}/api/member/update`, data, { headers: { authorization: "Bearer " + getToken(accessTokenKey) } }).then(result => {
    if (result.data === "Updated.") {
      dispatch(UpdateUserEdit(null));
      dispatch(LoginUser());
    }
  }).catch(error => {
    dispatch({ type: AppActions.ERROR, payload: { response: error.response, request: error } });
  });
}

export const UpdateAddressDetails = (data) => dispatch => {
  Axios.post(`${baseUrl}/api/member/updateaddress`, data, { headers: { authorization: "Bearer " + getToken(accessTokenKey) } }).then(result => {
    if (result.data === "Updated.") {
      dispatch(EditAddress(null));
      dispatch(LoginUser());
    }
  }).catch(error => {
    dispatch({ type: AppActions.ERROR, payload: { response: error.response, request: error } });
  });
}

export const UpdateSpouseDetails = (data) => dispatch => {
  Axios.post(`${baseUrl}/api/member/updatespouse`, data, { headers: { authorization: "Bearer " + getToken(accessTokenKey) } }).then(result => {
    if (result.data === "Updated.") {
      dispatch(EditSpouse(null));
      dispatch(LoginUser());
    }
  }).catch(error => {
    dispatch({ type: AppActions.ERROR, payload: { response: error.response, request: error } });
  });
}

export const UpdateKidsDetails = (data) => dispatch => {
  Axios.post(`${baseUrl}/api/member/updatekid`, data, { headers: { authorization: "Bearer " + getToken(accessTokenKey) } }).then(result => {
    if (result.data === "Updated.") {
      dispatch(EditKid(null));
      dispatch(LoginUser());
    }
  }).catch(error => {
    dispatch({ type: AppActions.ERROR, payload: { response: error.response, request: error } });
  });
}

export const GetQRCode = data => dispatch => {
  dispatch({ type: UserActions.QRCODE, payload: null });
  Axios.post(`${baseUrl}/api/member/QRCode?ids=${data}`, {}, { headers: { authorization: "Bearer " + getToken(accessTokenKey) } }).then(result => {
    dispatch({ type: UserActions.QRCODE, payload: result.data });
  }).catch(error => {
    dispatch({ type: AppActions.ERROR_ALERT, payload: error.response.data });
  });
}

export const UserSignup = (data) => dispatch => {
  dispatch({ type: AppActions.SHOW_PB });
  Axios.post(`${baseUrl}/api/member/signup`, data).then(result => {
    if (result.data === "AccountCreated.") {
      dispatch({ type: UserActions.SIGNUP_UPD, payload: { completed: true } });
    } else if (result.data === "User Already Exist.") {
      dispatch({ type: UserActions.SIGNUP_UPD, payload: { error: "User Already Exist." } });
    } else if (result.data === "Not a member.") {
      dispatch({ type: UserActions.SIGNUP_UPD, payload: { error: "Email id '" + data.email + "' not available in our system. Please contact board member for help." } });
    }
    dispatch({ type: AppActions.HIDE_PB });
  }).catch(error => {
    dispatch({ type: AppActions.ERROR, payload: { response: error.response, request: error } });
    dispatch({ type: AppActions.HIDE_PB });
  });
}

export const CheckActivateAccount = (data) => dispatch => {
  dispatch({ type: AppActions.SHOW_PB });
  Axios.post(`${baseUrl}/api/member/activate`, data).then(result => {
    if (result.data === "AccountActivated.") {
      dispatch({ type: UserActions.SIGNUP_UPD, payload: { activated: true } });
    } else if (result.data === "You account is activated already or the activation link Expired.") {
      dispatch({ type: UserActions.SIGNUP_UPD, payload: { error: "You account is activated already or the activation link Expired." } });
    } else if (result.data === "Not a member.") {
      dispatch({ type: UserActions.SIGNUP_UPD, payload: { error: "Email id '" + data.email + "' not available in our system. Please contact board member for help." } });
    }
    dispatch({ type: AppActions.HIDE_PB });
  }).catch(error => {
    dispatch({ type: AppActions.ERROR, payload: { response: error.response, request: error } });
    dispatch({ type: AppActions.HIDE_PB });
  });
}

export const ApproveMembershipRenewal = (data) => dispatch => {
  Axios.post(`${baseUrl}/api/admin/approverenewal`, data, { headers: { authorization: "Bearer " + getToken(accessTokenKey) } }).then(result => {
    dispatch(LoadAdminPage());
  }).catch(error => {
    dispatch({ type: AppActions.ERROR, payload: { response: error.response, request: error } });
  });
}
