import { BaseValidators } from './baseValidators';
const Validators = {
  ...BaseValidators
};
export function validate(value, key, namedValidators) {
 
 
  const result = { valid: true, errors: [] };
  if (typeof namedValidators === 'string') {
 
    namedValidators = [namedValidators];
  }
  
  namedValidators.map(validator => {
    
    const func = Validators[validator];
   
    if (typeof func === 'function') {
      if (validator === "passwordMatch"){
        const tRes = func(value[key], value[key.substring(7, key.length)]);
      
        if (!tRes.valid) {
          result.valid = false;
          result.errors = [...result.errors, ...tRes.errors];
        }
      } else {
        const tRes = func(value[key]);
       
        if (!tRes.valid) {
          result.valid = false;
          result.errors = [...result.errors, ...tRes.errors];
        }
      }
    }
    return true;
  });
  return result;
}
