import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { accessTokenKey, setToken } from '../Actions/utilActions';
import { CheckandLoginUser } from '../Actions';

function mapStateToProps({ app, user }) {
    return { app, user };
}

const mapDispatchToProps = dispatch => {
    return {
        loginUser: () => {
            dispatch(CheckandLoginUser());
        }
    };
};

class _OAuth2RedirectHandler extends Component {
    getUrlParameter(name) {
        name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');

        var results = regex.exec(this.props.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };
    componentDidMount() {
        const token = new URLSearchParams(this.props.location.search).get("token");
        if (token) {
            setToken(accessTokenKey, token);
            this.props.loginUser();
        }
    }
    componentDidUpdate() {
        if (this.props.user.user !== null) {
            this.props.history.push("/mypage");
        }
    }
    render() {
        const error = new URLSearchParams(this.props.location.search).get("error");
        if (error) {
            if (error.startsWith("You are not a registered member.")) {
                return (
                    <div className="page_container">
                        <div className='error_message_container'>
                            You are not a registered member. Please use the registration form to become member of our association and try this login once you become a member.
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="page_container">
                        <div className='error_message_container'>
                            {error}
                        </div>
                    </div>
                )
            }
        } else {
            return (
                <div className="page_container">
                    <div className='make_center'>
                        Redirecting...
                    </div>
                </div>
            )
        }
    }
}
export const OAuth2RedirectHandler = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_OAuth2RedirectHandler)
);